import {
  eventShownNameType,
  heptathlonEventType,
  heptathlonSingleRecord,
} from '../../common/types';
import { FC, memo, useEffect, useState } from 'react';
import '../../App.css';
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Button,
  Box,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { UpDownIcon } from '@chakra-ui/icons';
import { eventNameDict } from '../functions/eventNameDict';
import { shownResult } from '../functions/shownResult';
import {
  calcHeptathlonPoint,
  calcHeptathlonPointForSort,
  calcHeptathlonPointToJSXForSort,
} from '../functions/calcHeptathlonPointForResult';
import { createHeptathlonEventShownData } from '../functions/createHeptathlonScoreData';
import { approximateHeptathlonPointButton } from './approximateHeptathlonPointButton';
import { IntroductionHeptathlonModal } from './IntroductionHeptathlonModal';

export const ResultHeptathlonEventTable: FC<{
  Event: heptathlonEventType;
  Data: heptathlonSingleRecord[];
  csbData: heptathlonSingleRecord[];
  cpbData: heptathlonSingleRecord[];
  sbData: heptathlonSingleRecord[];
  pbData: heptathlonSingleRecord[];
}> =
  //  memo(
  ({ Event, Data, csbData, cpbData, sbData, pbData }) => {
    const [currentSort, setCurrentSort] = useState<eventShownNameType>('sb');
    // 表示させるためのデータ作成する
    const showData = Data.map((D, i) =>
      createHeptathlonEventShownData(
        Event,
        D,
        !!sbData[i] ? sbData[i] : sbData[0],
        !!pbData[i] ? pbData[i] : pbData[0],
        !!csbData[i] ? csbData[i] : csbData[0],
        !!cpbData[i] ? cpbData[i] : cpbData[0]
      )
    );
    const [data, setData] = useState(showData);
    const rank = Object.keys(data);
    const titles = Object.keys(showData[0]) as eventShownNameType[];
    const [clickName, setClickName] = useState('');
    const filledProfileNameList = Data.filter((f) => !!f['name']).map(
      (f) => f.name
    );
    const { isOpen, onOpen, onClose } = useDisclosure();
    //　データを取得した際にソートさせる
    useEffect(() => {
      setData(
        showData.slice().sort((a, b) => {
          return calcHeptathlonPoint(Event, a.result) >
            calcHeptathlonPoint(Event, b.result)
            ? -1
            : 1;
        })
      );
    }, [Data, csbData, cpbData, sbData, pbData]);

    // ソートボタンの実装
    const sortButton = (title: eventShownNameType) => {
      if (['name'].includes(title)) {
        return <>名前</>;
      }
      return (
        <Button size="xs" onClick={() => handlePointChange(title)}>
          {eventNameDict(title)}
          <UpDownIcon />
        </Button>
      );
    };
    const handlePointChange = (title: eventShownNameType) => {
      let newData = [...showData];
      setCurrentSort(title);
      const sortData = newData.sort((a, b) => {
        return calcHeptathlonPointForSort(title, Event, a[title]) >
          calcHeptathlonPointForSort(title, Event, b[title])
          ? -1
          : 1;
      });
      setData(sortData);
    };

    const handleNameClick = (NAME: string) => () => {
      setClickName(NAME);
      onOpen();
    };

    return (
      <>
        <IntroductionHeptathlonModal
          isOpen={isOpen}
          onClose={onClose}
          todayData={Data}
          CSB={csbData}
          CPB={cpbData}
          SB={sbData}
          PB={pbData}
          Name={clickName}
        />
        <Box ml="1" fontFamily="monospace" position="relative" zIndex="40">
          　　　　{eventNameDict(Event)}
          　　　　{approximateHeptathlonPointButton(Event)}
        </Box>
        <TableContainer>
          <Table size="sm" width="1">
            <TableCaption>HeptathlonResult</TableCaption>
            <Thead>
              <Tr>
                <Th textAlign={'center'}>
                  　順位　
                  <br />
                  {eventNameDict(currentSort)}
                </Th>

                {titles.map((title: eventShownNameType, i) => {
                  return (
                    <Th key={i} textAlign={'center'}>
                      {sortButton(title)}
                    </Th>
                  );
                })}
              </Tr>
            </Thead>
            <Tbody>
              {data.map((singledata, i) => {
                return (
                  <Tr key={i}>
                    <Td textAlign={'center'}>{Number(rank[i]) + 1}</Td>
                    <Td textAlign={'center'}>
                      {(filledProfileNameList.includes(singledata.name) && (
                        <>
                          <Text
                            fontSize={'9.5pt'}
                            fontWeight={'bold'}
                            color={'#4169e1'}
                            onClick={handleNameClick(singledata.name)}
                          >
                            {singledata.name.split('-')[0] || ''}
                          </Text>
                        </>
                      )) || (
                        <Text fontSize={'9.5pt'} fontWeight={'bold'}>
                          {singledata.name.split('-')[0] || ''}
                        </Text>
                      )}
                      <Text fontSize={'7pt'} color={'GrayText'}>
                        {singledata.name.split('-')[1] || 'なし'}
                      </Text>
                    </Td>

                    {Object.entries(singledata).map((row, i) => {
                      const kiroku = row[1];
                      const point = calcHeptathlonPointToJSXForSort(
                        row[0],
                        Event,
                        row[1]
                      );
                      if (row[0] === 'name') {
                        return null;
                      }
                      return (
                        <Td key={i} textAlign={'center'}>
                          {shownResult(kiroku, row[0])}
                          <br />
                          {point}
                        </Td>
                      );
                    })}
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </TableContainer>
      </>
    );
  };
// );
