import React from 'react';
import { Button, useDisclosure } from '@chakra-ui/react';
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
} from '@chakra-ui/react';

export const HowToUse = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const cancelRef = React.useRef(null);

  return (
    <>
      <Button variant="link" size="xs" onClick={onOpen}>
        <u>使い方</u>
      </Button>

      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              使い方{' '}
            </AlertDialogHeader>

            <AlertDialogBody>
              記録の入力は秒やmを除いて入力してください
              <br />
              <br />
              例)
              <br />
              10秒76 → 1076
              <br />
              7m54 → 754
              <br />
              4分16秒55 → 41655
              <br />
              <br />
              得点から記録も計算できます
              <br />
              <br />
              <Button size="xs">Save/Load</Button>
              <br />
              このボタンを押すと一時的にデータを保存できます
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                戻る
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};
