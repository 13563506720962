export const spreadSheetLink = {
  profile:
    'https://docs.google.com/spreadsheets/d/e/2PACX-1vTshLj3hhvRDAgToETLwEPx2Ylop6PDdCzMuiYFAXmPIdyFPwRbG_BdFW9NqvEQ_FNUFqGcx3kq4GFx/pub?gid=566380489&single=true&output=csv',
  decaResult:
    'https://docs.google.com/spreadsheets/d/e/2PACX-1vTshLj3hhvRDAgToETLwEPx2Ylop6PDdCzMuiYFAXmPIdyFPwRbG_BdFW9NqvEQ_FNUFqGcx3kq4GFx/pub?gid=0&single=true&output=csv',
  CSB: 'https://docs.google.com/spreadsheets/d/e/2PACX-1vTshLj3hhvRDAgToETLwEPx2Ylop6PDdCzMuiYFAXmPIdyFPwRbG_BdFW9NqvEQ_FNUFqGcx3kq4GFx/pub?gid=1399017830&single=true&output=csv',
  CPB: 'https://docs.google.com/spreadsheets/d/e/2PACX-1vTshLj3hhvRDAgToETLwEPx2Ylop6PDdCzMuiYFAXmPIdyFPwRbG_BdFW9NqvEQ_FNUFqGcx3kq4GFx/pub?gid=771134663&single=true&output=csv',
  SB: 'https://docs.google.com/spreadsheets/d/e/2PACX-1vTshLj3hhvRDAgToETLwEPx2Ylop6PDdCzMuiYFAXmPIdyFPwRbG_BdFW9NqvEQ_FNUFqGcx3kq4GFx/pub?gid=1920768843&single=true&output=csv',
  PB: 'https://docs.google.com/spreadsheets/d/e/2PACX-1vTshLj3hhvRDAgToETLwEPx2Ylop6PDdCzMuiYFAXmPIdyFPwRbG_BdFW9NqvEQ_FNUFqGcx3kq4GFx/pub?gid=1981581478&single=true&output=csv',
  hepResult:
    'https://docs.google.com/spreadsheets/d/e/2PACX-1vTshLj3hhvRDAgToETLwEPx2Ylop6PDdCzMuiYFAXmPIdyFPwRbG_BdFW9NqvEQ_FNUFqGcx3kq4GFx/pub?gid=1645265925&single=true&output=csv',
  hepCSB:
    'https://docs.google.com/spreadsheets/d/e/2PACX-1vTshLj3hhvRDAgToETLwEPx2Ylop6PDdCzMuiYFAXmPIdyFPwRbG_BdFW9NqvEQ_FNUFqGcx3kq4GFx/pub?gid=937771338&single=true&output=csv',
  hepCPB:
    'https://docs.google.com/spreadsheets/d/e/2PACX-1vTshLj3hhvRDAgToETLwEPx2Ylop6PDdCzMuiYFAXmPIdyFPwRbG_BdFW9NqvEQ_FNUFqGcx3kq4GFx/pub?gid=985746532&single=true&output=csv',
  hepSB:
    'https://docs.google.com/spreadsheets/d/e/2PACX-1vTshLj3hhvRDAgToETLwEPx2Ylop6PDdCzMuiYFAXmPIdyFPwRbG_BdFW9NqvEQ_FNUFqGcx3kq4GFx/pub?gid=308428280&single=true&output=csv',
  hepPB:
    'https://docs.google.com/spreadsheets/d/e/2PACX-1vTshLj3hhvRDAgToETLwEPx2Ylop6PDdCzMuiYFAXmPIdyFPwRbG_BdFW9NqvEQ_FNUFqGcx3kq4GFx/pub?gid=1792059955&single=true&output=csv',
  U20decaResult:
    'https://docs.google.com/spreadsheets/d/e/2PACX-1vTshLj3hhvRDAgToETLwEPx2Ylop6PDdCzMuiYFAXmPIdyFPwRbG_BdFW9NqvEQ_FNUFqGcx3kq4GFx/pub?gid=415144825&single=true&output=csv',
  U20CSB:
    'https://docs.google.com/spreadsheets/d/e/2PACX-1vTshLj3hhvRDAgToETLwEPx2Ylop6PDdCzMuiYFAXmPIdyFPwRbG_BdFW9NqvEQ_FNUFqGcx3kq4GFx/pub?gid=609864368&single=true&output=csv',
  U20CPB:
    'https://docs.google.com/spreadsheets/d/e/2PACX-1vTshLj3hhvRDAgToETLwEPx2Ylop6PDdCzMuiYFAXmPIdyFPwRbG_BdFW9NqvEQ_FNUFqGcx3kq4GFx/pub?gid=666690923&single=true&output=csv',
  U20SB:
    'https://docs.google.com/spreadsheets/d/e/2PACX-1vTshLj3hhvRDAgToETLwEPx2Ylop6PDdCzMuiYFAXmPIdyFPwRbG_BdFW9NqvEQ_FNUFqGcx3kq4GFx/pub?gid=129349517&single=true&output=csv',
  U20PB:
    'https://docs.google.com/spreadsheets/d/e/2PACX-1vTshLj3hhvRDAgToETLwEPx2Ylop6PDdCzMuiYFAXmPIdyFPwRbG_BdFW9NqvEQ_FNUFqGcx3kq4GFx/pub?gid=590713450&single=true&output=csv',
  U20hepResult:
    'https://docs.google.com/spreadsheets/d/e/2PACX-1vTshLj3hhvRDAgToETLwEPx2Ylop6PDdCzMuiYFAXmPIdyFPwRbG_BdFW9NqvEQ_FNUFqGcx3kq4GFx/pub?gid=659210348&single=true&output=csv',
  U20hepCSB:
    'https://docs.google.com/spreadsheets/d/e/2PACX-1vTshLj3hhvRDAgToETLwEPx2Ylop6PDdCzMuiYFAXmPIdyFPwRbG_BdFW9NqvEQ_FNUFqGcx3kq4GFx/pub?gid=712731145&single=true&output=csv',
  U20hepCPB:
    'https://docs.google.com/spreadsheets/d/e/2PACX-1vTshLj3hhvRDAgToETLwEPx2Ylop6PDdCzMuiYFAXmPIdyFPwRbG_BdFW9NqvEQ_FNUFqGcx3kq4GFx/pub?gid=958043637&single=true&output=csv',
  U20hepSB:
    'https://docs.google.com/spreadsheets/d/e/2PACX-1vTshLj3hhvRDAgToETLwEPx2Ylop6PDdCzMuiYFAXmPIdyFPwRbG_BdFW9NqvEQ_FNUFqGcx3kq4GFx/pub?gid=532781537&single=true&output=csv',
  U20hepPB:
    'https://docs.google.com/spreadsheets/d/e/2PACX-1vTshLj3hhvRDAgToETLwEPx2Ylop6PDdCzMuiYFAXmPIdyFPwRbG_BdFW9NqvEQ_FNUFqGcx3kq4GFx/pub?gid=597874328&single=true&output=csv',
};
