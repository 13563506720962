import React, { useState } from 'react';
import '../App.css';
import { ResultTable } from './components/ResultTable';
import {
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Heading,
  Box,
} from '@chakra-ui/react';
import { ResultEventTable } from './components/ResultEventTable';
import { eventListType, eventType } from '../common/types';
import { createForecastData } from './functions/createScoreData';
import { useResultData } from './functions/getData';
import {
  useCPBData,
  useCSBData,
  usePBData,
  useSBData,
} from './request/requests';
import { ResultLink } from './components/ResultLink';
import { dateGame, titleGame } from './components/TitleGame';

export const DecathlonResultTable = () => {
  const EventList: eventListType = {
    i00: '100m',
    lj: '走幅跳',
    sp: '砲丸投',
    hj: '走高跳',
    A00: '400m',
    ii0h: '110mH',
    dt: '円盤投',
    pv: '棒高跳',
    jt: 'やり投',
    i500: '1500m',
  };

  //  papa.parseでスプレッドシートから記録を取得する
  const dataFromSpreadSheet = useResultData();

  const csbDataInitial = useCSBData();
  const cpbDataInitial = useCPBData();
  const sbData = useSBData();
  const pbData = usePBData();

  // const profileData = useProfileData();

  //  現在終了種目時点での予想記録を作成する
  const sbForecastData = dataFromSpreadSheet.map((d, i) =>
    createForecastData(
      d,
      !!csbDataInitial[i] ? csbDataInitial[i] : csbDataInitial[0]
    )
  );
  const pbForecastData = dataFromSpreadSheet.map((d, i) =>
    createForecastData(
      d,
      !!cpbDataInitial[i] ? cpbDataInitial[i] : cpbDataInitial[0]
    )
  );

  const handleUpdate = () => {
    // 更新のロジックを実装する部分
    window.location.reload();

    // ボタンがクリックされたときに実行される関数です
  };

  // タブスライダーの実装
  // const [tabIndex, setTabIndex] = useState(0);
  // const handleSliderChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   setTabIndex(parseInt(event.target.value, 10));
  // };
  // const handleTabsChange = (index: number) => {
  //   setTabIndex(index);
  // };

  return (
    <>
      <Box m={'8px'}>
        <Heading as="h4" size="md">
          {/* 2023/6/10~11
          <br /> 第107回 日本選手権混成 */}
          {dateGame}
          <br />
          {titleGame}
        </Heading>
        <ResultLink />
      </Box>

      <Tabs
        size="sm"
        variant="soft-rounded"
        colorScheme="green"
        // index={tabIndex}
        // onChange={handleTabsChange}
      >
        <TabList overflowX="auto" whiteSpace="nowrap">
          <Tab>総合順位</Tab>

          <Tab>
            予想順位
            <br />
            (SB換算)
          </Tab>
          <Tab>
            予想順位
            <br />
            (PB換算)
          </Tab>
          {Object.values(EventList).map((event, i) => {
            return <Tab key={i}>{event}</Tab>;
          })}
          <Tab>SB順位</Tab>
          <Tab>PB順位</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <ResultTable
              Data={dataFromSpreadSheet}
              rawData={dataFromSpreadSheet}
              title="総合順位"
              // profileData={profileData}
              csbData={csbDataInitial}
              cpbData={cpbDataInitial}
              sbData={sbData}
              pbData={pbData}
            />
          </TabPanel>
          <TabPanel>
            <ResultTable
              Data={sbForecastData}
              rawData={dataFromSpreadSheet}
              title="予想順位(SB換算)"
              // profileData={profileData}
              csbData={csbDataInitial}
              cpbData={cpbDataInitial}
              sbData={sbData}
              pbData={pbData}
            />
          </TabPanel>
          <TabPanel>
            <ResultTable
              Data={pbForecastData}
              rawData={dataFromSpreadSheet}
              title="予想順位(PB換算)"
              // profileData={profileData}
              csbData={csbDataInitial}
              cpbData={cpbDataInitial}
              sbData={sbData}
              pbData={pbData}
            />
          </TabPanel>
          {Object.keys(EventList).map((event, i) => {
            const e = event as eventType;
            return (
              <TabPanel key={i}>
                <ResultEventTable
                  Event={e}
                  Data={dataFromSpreadSheet}
                  csbData={csbDataInitial}
                  cpbData={cpbDataInitial}
                  sbData={sbData}
                  pbData={pbData}
                />
              </TabPanel>
            );
          })}
          <TabPanel>
            <ResultTable
              Data={csbDataInitial}
              rawData={dataFromSpreadSheet}
              title="SB順位"
              // profileData={profileData}
              csbData={csbDataInitial}
              cpbData={cpbDataInitial}
              sbData={sbData}
              pbData={pbData}
            />
          </TabPanel>
          <TabPanel>
            <ResultTable
              Data={cpbDataInitial}
              rawData={dataFromSpreadSheet}
              title="PB順位"
              // profileData={profileData}
              csbData={csbDataInitial}
              cpbData={cpbDataInitial}
              sbData={sbData}
              pbData={pbData}
            />
          </TabPanel>
        </TabPanels>
      </Tabs>

      {/* <Input
        type="range"
        min="0"
        max="12"
        value={tabIndex}
        onChange={handleSliderChange}
        position="fixed"
        bottom="0"
        zIndex="30"
        backgroundColor="white"
      /> */}
    </>
  );
};
