import { Button } from "@chakra-ui/react";
import React, { useState } from "react";
import {
  indoorPentathlonEventListType,
  indoorPentathlonEventType,
} from "../common/types";
import { IndoorcalcPoint, IndoorcalcRecord } from "./components/calcWomanPoint";
import { eventNameDict } from "./components/eventNameDict";
import { HowToUse } from "./components/howToUse";
import { Links } from "./components/links";
import { ScoreInput } from "./components/scoreInput";

export const CalculatorIndoorPentathlon = () => {
  const [records, setRecords] = useState<indoorPentathlonEventListType>({
    b0h: "",
    hj: "",
    sp: "",
    lj: "",
    B00: "",
  });

  // 得点もinputでいじるのでこれも箱として良い
  const [points, setPoints] = useState<indoorPentathlonEventListType>({
    b0h: "",
    hj: "",
    sp: "",
    lj: "",
    B00: "",
  });

  // 種目名を入れるとそれより前に行われたやつを全部足す関数
  const calcTotalPoint = (EventName: indoorPentathlonEventType) => {
    let totalPoint = 0;
    for (const event in points) {
      const score = Number(points[event as indoorPentathlonEventType]) || 0;
      totalPoint += score;
      if (event === EventName) {
        break;
      }
    }
    return totalPoint;
  };

  const handleChange =
    (key: indoorPentathlonEventType) =>
    (score: React.ChangeEvent<HTMLInputElement>) => {
      const record = Number(score.target.value);
      setRecords({ ...records, [key]: record });
      const calucalatedPoint = IndoorcalcPoint(key, record);
      setPoints({ ...points, [key]: calucalatedPoint });
    };

  const handlePointChangeParent =
    (key: indoorPentathlonEventType) =>
    (result: React.ChangeEvent<HTMLInputElement>) => {
      const point = Number(result.target.value);
      setPoints({ ...points, [key]: point });
      const score = IndoorcalcRecord(key, point);
      setRecords({ ...records, [key]: score });
    };

  const reset = () => {
    setPoints({
      b0h: "",
      hj: "",
      sp: "",
      lj: "",
      B00: "",
    });
    setRecords({
      b0h: "",
      hj: "",
      sp: "",
      lj: "",
      B00: "",
    });
  };
  const eventList = Object.keys(records) as indoorPentathlonEventType[];

  return (
    <div>
      {/* <CustomHeader /> */}

      <div
        style={{ marginLeft: "100px", marginTop: "16px", marginBottom: "16px" }}
      >
        女子室内五種競技得点表
      </div>
      <div style={{ display: "flex", width: "360px", marginBottom: "10px" }}>
        <div style={{ marginLeft: "36px", width: "65%" }}>
          <HowToUse />
        </div>
        <Button
          onClick={reset}
          size="xs"
          style={{ color: "black", backgroundColor: "#fff4fd" }}
        >
          Reset
        </Button>
      </div>
      {eventList.map((eN, index) => {
        const color = index % 2 === 0 ? "#fff4fd" : "#ffffff";
        return (
          <div style={{ backgroundColor: color }} key={index}>
            <ScoreInput
              title={eventNameDict[eN]}
              record={records[eN]}
              point={points[eN]}
              handleScoreChange={handleChange(eN)}
              totalScore={calcTotalPoint(eN)}
              handlePointChange={handlePointChangeParent(eN)}
            />
          </div>
        );
      })}

      <div style={{ marginLeft: "16px" }}>---総合---</div>
      <div
        style={{
          display: "flex",
          width: "360px",
          textAlign: "center",
        }}
      >
        <div style={{ width: "30%", marginLeft: "10pt" }}>- </div>
        <div style={{ width: "30%", marginLeft: "10pt" }}>- </div>
        <div style={{ width: "20%", textAlign: "right" }}>合計：</div>
        <div style={{ width: "20%", textAlign: "right", marginRight: "1pt" }}>
          {calcTotalPoint("B00")} 点
        </div>
      </div>
      <div style={{ marginTop: "10px", marginLeft: "270px" }}>
        <Button
          onClick={reset}
          size="xs"
          style={{ color: "black", backgroundColor: "#fff4fd" }}
        >
          Reset
        </Button>
      </div>
      <Links />
    </div>
  );
};
