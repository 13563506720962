// import { CustomHeader } from "./common/components/text";
import { Box } from "@chakra-ui/react";
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from "@chakra-ui/react";
import { Links } from "../page_calculator/components/links";
import { manRecordList, womanRecordList } from "./components/recordList";
import { RecordTable } from "./components/recordTable";
import { WomanRecordTable } from "./components/womanRecordTable";

export const VariousCombinedEventsRecord = () => {
  return (
    <>
      {/* <CustomHeader /> */}
      <Accordion defaultIndex={[0, 1]} allowMultiple>
        <AccordionItem>
          <h2>
            <AccordionButton>
              <Box as="span" flex="1" textAlign="left">
                １０種競技
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            <div>
              <Accordion allowMultiple>
                {manRecordList.map((record, i) => {
                  return (
                    <AccordionItem key={i}>
                      <h2>
                        <AccordionButton>
                          <Box as="span" flex="1" textAlign="left">
                            {record.title}
                          </Box>
                          <AccordionIcon />
                        </AccordionButton>
                      </h2>
                      <AccordionPanel pb={4}>
                        <div>
                          <RecordTable recordList={record.records} />
                        </div>
                      </AccordionPanel>
                    </AccordionItem>
                  );
                })}
              </Accordion>
            </div>
          </AccordionPanel>
        </AccordionItem>

        <AccordionItem>
          <h2>
            <AccordionButton>
              <Box as="span" flex="1" textAlign="left">
                ７種競技
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            <div>
              <Accordion allowMultiple>
                {womanRecordList.map((wrecord, i) => {
                  return (
                    <AccordionItem key={i}>
                      <h2>
                        <AccordionButton>
                          <Box as="span" flex="1" textAlign="left">
                            {wrecord.title}
                          </Box>
                          <AccordionIcon />
                        </AccordionButton>
                      </h2>
                      <AccordionPanel pb={4}>
                        <div>
                          <WomanRecordTable recordList={wrecord.records} />
                        </div>
                      </AccordionPanel>
                    </AccordionItem>
                  );
                })}
              </Accordion>
            </div>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
      <div>
        <Links />
      </div>
    </>
  );
};
