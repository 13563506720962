import React from 'react';
import { Link } from 'react-router-dom';

export const Links = () => {
  return (
    <>
      <div
        style={{ marginTop: '16px', marginLeft: '16px', marginBottom: '64px' }}
      >
        <div>↓リンク集↓</div>
        <Link to={'/decathlonlist'}>
          <u>得点差はどれくらい？</u>
        </Link>
        <br />
        <Link to={'/variousrecord'}>
          <u>全部世界記録なら何点になる？</u>
        </Link>
        <br />
        <Link to={'/decathloncalculator'}>
          <u>10種競技得点表</u>
        </Link>
        <br />
        <Link to={`/heptathloncalculator`}>
          <u>7種競技得点表</u>
        </Link>
        <br />
        <Link to={`/octathloncalculator`}>
          <u>8種競技得点表</u>
        </Link>
        <br />
        <Link to={'/indoorheptathloncalculator'}>
          <u>男子室内７種競技得点表</u>
        </Link>
        <br />
        <Link to={'/indoorpentathloncalculator'}>
          <u>女子室内５種競技得点表</u>
        </Link>
        <br />
        <Link to={'/tetrathlonmancalculator'}>
          <u>中学生男子４種競技得点表</u>
        </Link>
        <br />
        <Link to={'/tetrathlonwomancalculator'}>
          <u>中学生女子４種競技得点表</u>
        </Link>
        <br />
        <Link to={'/dec'}>
          <u>混成速報サイト</u>
        </Link>
      </div>
    </>
  );
};
