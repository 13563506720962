import { Box } from '@chakra-ui/react';

export const DecaPointgaps = {
  i00: (
    <Box>
      0.01秒あたり約2点
      <br />
      0.1秒あたり約22点
      <br />
      <br />
      10点差は約0.04秒
      <br />
      100点差は約0.45秒
    </Box>
  ),
  lj: (
    <Box>
      1cmあたり約2~3点
      <br />
      10cmあたり約24点
      <br />
      <br />
      10点差は約4cm
      <br />
      100点差は約43cm
    </Box>
  ),
  sp: (
    <Box>
      10cmあたり約6点
      <br />
      1mあたり約60点
      <br />
      <br />
      10点差は約16cm
      <br />
      100点差は約1m65cm
    </Box>
  ),
  hj: (
    <Box>
      1cmあたり約9点
      <br />
      10cmあたり約88点
      <br />
      <br />
      10点差は約1cm
      <br />
      100点差は約11cm
    </Box>
  ),
  A00: (
    <Box>
      0.1秒あたり約5点
      <br />
      1秒あたり約49点
      <br />
      <br />
      10点差は約0.2秒
      <br />
      100点差は約2.1秒
    </Box>
  ),
  ii0h: (
    <Box>
      0.01秒あたり約1~2点
      <br />
      0.1秒あたり約13点
      <br />
      <br />
      10点差は約0.08秒
      <br />
      100点差は約0.8秒
    </Box>
  ),
  dt: (
    <Box>
      1mあたり約20点
      <br />
      5mあたり約100点
      <br />
      <br />
      10点差は約50cm
      <br />
      100点差は約5m
    </Box>
  ),
  pv: (
    <Box>
      10cmあたり約30点
      <br />
      50cmあたり約150点
      <br />
      <br />
      10点差は約3cm
      <br />
      100点差は約33cm
    </Box>
  ),
  jt: (
    <Box>
      1mあたり約15点
      <br />
      5mあたり約75点
      <br />
      <br />
      10点差は約66cm
      <br />
      100点差は約6m60cm
    </Box>
  ),
  i500: (
    <Box>
      1秒あたり約6~7点
      <br />
      10秒あたり約60~70点
      <br />
      <br />
      10点差は約1.5秒
      <br />
      100点差は約15秒
    </Box>
  ),
};

export const OctaPointgaps = {
  i00: (
    <Box>
      0.01秒あたり約2点
      <br />
      0.1秒あたり約22点
      <br />
      <br />
      10点差は約0.04~0.05秒
      <br />
      100点差は約0.45秒
    </Box>
  ),
  lj: (
    <Box>
      1cmあたり約2~3点
      <br />
      10cmあたり約24点
      <br />
      <br />
      10点差は約4~5cm
      <br />
      100点差は約43cm
    </Box>
  ),
  sp: (
    <Box>
      10cmあたり約6点
      <br />
      1mあたり約60点
      <br />
      <br />
      10点差は約16cm
      <br />
      100点差は約1m65cm
    </Box>
  ),
  A00: (
    <Box>
      0.1秒あたり約5点
      <br />
      1秒あたり約49点
      <br />
      <br />
      10点差は約0.2秒
      <br />
      100点差は約2.1秒
    </Box>
  ),
  ii0h: (
    <Box>
      0.01秒あたり約1~2点
      <br />
      0.1秒あたり約13点
      <br />
      <br />
      10点差は約0.08秒
      <br />
      100点差は約0.8秒
    </Box>
  ),
  jt: (
    <Box>
      1mあたり約15点
      <br />
      5mあたり約75点
      <br />
      <br />
      10点差は約66cm
      <br />
      100点差は約6m60cm
    </Box>
  ),
  hj: (
    <Box>
      1cmあたり約9点
      <br />
      10cmあたり約88点
      <br />
      <br />
      10点差は約1cm
      <br />
      100点差は約11cm
    </Box>
  ),
  i500: (
    <Box>
      1秒あたり約6~7点
      <br />
      10秒あたり約60~70点
      <br />
      <br />
      10点差は約1.5秒
      <br />
      100点差は約15秒
    </Box>
  ),
};

export const HepPointgaps = {
  i00h: (
    <Box>
      0.01秒あたり約1~2点
      <br />
      0.1秒あたり約15点
      <br />
      <br />
      10点差は約0.07秒
      <br />
      100点差は約0.7秒
    </Box>
  ),
  hj: (
    <Box>
      1cmあたり約12点
      <br />
      10cmあたり約115点
      <br />
      <br />
      10点差は約1cm
      <br />
      100点差は約9cm
    </Box>
  ),
  sp: (
    <Box>
      10cmあたり約6~7点
      <br />
      1mあたり約66点
      <br />
      <br />
      10点差は約15cm
      <br />
      100点差は約1m50cm
    </Box>
  ),
  z00: (
    <Box>
      0.01秒あたり約1点
      <br />
      0.1秒あたり約10点
      <br />
      <br />
      10点差は約0.1秒
      <br />
      100点差は約1.1秒
    </Box>
  ),
  lj: (
    <Box>
      1cmあたり約3点
      <br />
      10cmあたり約30点
      <br />
      <br />
      10点差は約3cm
      <br />
      100点差は約33cm
    </Box>
  ),
  jt: (
    <Box>
      1mあたり約19点
      <br />
      5mあたり約95点
      <br />
      <br />
      10点差は約52cm
      <br />
      100点差は約5.2m
    </Box>
  ),
  B00: (
    <Box>
      1秒あたり約13点
      <br />
      5秒あたり約65点
      <br />
      <br />
      10点差は約0.75秒
      <br />
      100点差は約7.5秒
    </Box>
  ),
};

export const TetraManPointgaps = {
  ii0h: (
    <Box>
      0.01秒あたり約1~2点
      <br />
      0.1秒あたり約13点
      <br />
      <br />
      10点差は約0.08秒
      <br />
      100点差は約0.8秒
    </Box>
  ),
  sp: (
    <Box>
      10cmあたり約6点
      <br />
      1mあたり約60点
      <br />
      <br />
      10点差は約16cm
      <br />
      100点差は約1m65cm
    </Box>
  ),
  hj: (
    <Box>
      1cmあたり約9点
      <br />
      10cmあたり約88点
      <br />
      <br />
      10点差は約1cm
      <br />
      100点差は約11cm
    </Box>
  ),
  A00: (
    <Box>
      0.1秒あたり約5点
      <br />
      1秒あたり約49点
      <br />
      <br />
      10点差は約0.2秒
      <br />
      100点差は約2.1秒
    </Box>
  ),
};

export const TetraWomanPointgaps = {
  i00h: (
    <Box>
      0.01秒あたり約1~2点
      <br />
      0.1秒あたり約15点
      <br />
      <br />
      10点差は約0.07秒
      <br />
      100点差は約0.7秒
    </Box>
  ),
  hj: (
    <Box>
      1cmあたり約12点
      <br />
      10cmあたり約115点
      <br />
      <br />
      10点差は約1cm
      <br />
      100点差は約9cm
    </Box>
  ),
  sp: (
    <Box>
      10cmあたり約6~7点
      <br />
      1mあたり約66点
      <br />
      <br />
      10点差は約15cm
      <br />
      100点差は約1m50cm
    </Box>
  ),
  z00: (
    <Box>
      0.01秒あたり約1点
      <br />
      0.1秒あたり約10点
      <br />
      <br />
      10点差は約0.1秒
      <br />
      100点差は約1.1秒
    </Box>
  ),
};
