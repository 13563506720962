import React, { FC } from "react";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
} from "@chakra-ui/react";
import { heptathlonRecordType } from "./recordList";
import { heptathlonEventType } from "../../common/types";
import { eventNameDict } from "../../page_ResultTable/functions/eventNameDict";
import { calcPoint } from "../../page_calculator/components/calcWomanPoint";

export const WomanRecordTable: FC<{
  recordList: heptathlonRecordType;
}> = ({ recordList }) => {
  return (
    <div>
      <TableContainer>
        <Table size="sm" variant="simple" width="1">
          <TableCaption>Decathlon/HeptathlonScoreTable(~2022)</TableCaption>
          <Thead>
            <Tr>
              <Th>種目</Th>
              <Th>記録</Th>
              <Th>得点</Th>
              <Th>合計</Th>
            </Tr>
          </Thead>
          <Tbody>
            {Object.entries(recordList).map((_, index) => {
              const title = Object.keys(recordList)[index];

              const kiroku =
                title === "day1" || title === "day2"
                  ? 0
                  : Number(Object.values(recordList)[index].record);

              const tokuten =
                title === "day1" || title === "day2"
                  ? Number(Object.values(recordList)[index].record)
                  : Number(calcPoint(title as heptathlonEventType, kiroku));

              const totalcalc = (idx: number) => {
                let total = 0;
                for (let i = 0; i <= idx; i++) {
                  if (idx >= 10) {
                    break;
                  }
                  const point = Number(
                    calcPoint(
                      Object.keys(recordList)[i] as heptathlonEventType,
                      Number(Object.values(recordList)[i].record)
                    )
                  );
                  total += point;
                }
                return total;
              };

              return (
                <Tr key={index}>
                  <Td>{eventNameDict(title)}</Td>
                  <Td>
                    {kiroku || "-"}
                    <div style={{ fontSize: "10px" }}>
                      ({Object.values(recordList)[index].name})
                    </div>
                  </Td>
                  <Td>{tokuten}点</Td>
                  <Td>{totalcalc(index) || "-"}点</Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </TableContainer>
    </div>
  );
};
