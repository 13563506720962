import { DeleteIcon } from '@chakra-ui/icons';

import {
  Button,
  Flex,
  Input,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  UnorderedList,
  useDisclosure,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { octathlonEventListType, octathlonEventType } from '../common/types';
import { calcRecordByName, calcScoreByName } from './components/calcPoint';
import { eventNameDict } from './components/eventNameDict';
import { HowToName } from './components/howToName';
import { HowToUse } from './components/howToUse';
import { Links } from './components/links';
import { ScoreInput } from './components/scoreInput';

export const CalculatorOctathlonSave = () => {
  //記録の箱
  const [records, setRecords] = useState<octathlonEventListType>({
    i00: '',
    lj: '',
    sp: '',
    A00: '',
    ii0h: '',
    jt: '',
    hj: '',
    i500: '',
  });

  // 得点もinputでいじるのでこれも箱として良い
  const [points, setPoints] = useState<octathlonEventListType>({
    i00: '',
    lj: '',
    sp: '',
    A00: '',
    ii0h: '',
    jt: '',
    hj: '',
    i500: '',
  });

  // 種目名を入れるとそれより前に行われたやつを全部足す関数
  const calcTotalPoint = (EventName: octathlonEventType) => {
    let totalPoint = 0;
    for (const event in points) {
      const score = Number(points[event as octathlonEventType]) || 0;
      totalPoint += score;
      if (event === EventName) {
        break;
      }
    }
    return totalPoint;
  };

  //記録を入力すると得点が表示される関数
  const handleChange =
    (key: octathlonEventType) =>
    (score: React.ChangeEvent<HTMLInputElement>) => {
      const record = Number(score.target.value);
      setRecords({ ...records, [key]: record });
      const calucalatedPoint = calcScoreByName(key, record);
      setPoints({ ...points, [key]: calucalatedPoint });
    };

  //得点を入力すると記録が表示される関数
  const handlePointChangeParent =
    (key: octathlonEventType) =>
    (result: React.ChangeEvent<HTMLInputElement>) => {
      const point = Number(result.target.value);
      setPoints({ ...points, [key]: point });
      const score = calcRecordByName(key, point);
      setRecords({ ...records, [key]: score });
    };

  //記録と得点をリセットする関数
  const reset = () => {
    setPoints({
      i00: '',
      lj: '',
      sp: '',
      A00: '',
      ii0h: '',
      jt: '',
      hj: '',
      i500: '',
    });
    setRecords({
      i00: '',
      lj: '',
      sp: '',
      A00: '',
      ii0h: '',
      jt: '',
      hj: '',
      i500: '',
    });
  };
  const eventList = Object.keys(records) as octathlonEventType[];

  //ここからSAVE用のモーダル部分

  const {
    isOpen: isOpenLoad,
    onOpen: onOpenLoad,
    onClose: onCloseLoad,
  } = useDisclosure();
  const [inputName, setInputName] = useState<string>('');
  const [dataList, setDataList] = useState<string[]>([]);
  const [selectedData, setSelectedData] = useState<string | null>(null);

  // ローカルストレージからデータを取得して初期化
  useEffect(() => {
    const storageData = localStorage.getItem('OctaData');

    if (storageData) {
      const parsedData: {
        name: string;
        data: octathlonEventListType;
        point: octathlonEventListType;
      }[] = JSON.parse(storageData);
      const savedData: string[] = parsedData.map((item) => item.name);
      setDataList(savedData);
    }
  }, []);

  interface Data {
    name: string;
    data: octathlonEventListType;
    point: octathlonEventListType;
  }

  const saveData = (): void => {
    if (inputName.trim() !== '') {
      const newData: Data = {
        name: inputName,
        data: records,
        point: points,
      };
      const existingData: Data[] = JSON.parse(
        localStorage.getItem('OctaData') || '[]'
      ) as Data[];

      // 同じ名前を持つデータが存在するかチェック
      const isNameExist = existingData.some(
        (data: Data) => data.name === inputName
      ); // ここで `Data` 型を明示

      if (!isNameExist) {
        localStorage.setItem(
          'OctaData',
          JSON.stringify([...existingData, newData])
        );
        setDataList((prevDataList: string[]) => [...prevDataList, inputName]);
        setInputName('');
      } else {
        alert('この名前は使用されています');
      }
    }
  };

  const deleteData = (deletedItem: string) => {
    const storageData = localStorage.getItem('OctaData');
    let parsedData = null;
    if (storageData) {
      parsedData = JSON.parse(storageData) as {
        name: string;
        data: octathlonEventListType;
        point: octathlonEventListType;
      }[];
    }
    if (!parsedData) return;

    const updatedDataList = parsedData.filter(
      (item) => item.name !== deletedItem
    );
    const dataNameList = updatedDataList.map((d) => d.name);
    setDataList(dataNameList);
    localStorage.setItem('OctaData', JSON.stringify(updatedDataList)); // 最初の要素を保存するか、データがない場合は空文字列を保存
    // 削除されたデータが選択されていた場合、選択をリセット
    if (selectedData === deletedItem) {
      setSelectedData(null);
    }
  };

  const showSelectedData = (selectedItem: string) => {
    const storageData = localStorage.getItem('OctaData');

    if (storageData) {
      const parsedData: {
        name: string;
        data: octathlonEventListType;
        point: octathlonEventListType;
      }[] = JSON.parse(storageData);

      console.log('parseData', parsedData);

      const selectedData = parsedData.find(
        (item) => item.name === selectedItem
      );

      if (selectedData) {
        // 選択されたデータが見つかった場合の処理
        console.log('Selected Data:', selectedData);
        setRecords(selectedData.data); // dataプロパティだけをセット
        setPoints(selectedData.point); // dataプロパティだけをセット
      }
    }

    setSelectedData(selectedItem);
    onCloseLoad();
  };

  return (
    <div>
      {/* タイトル部分の実装 */}
      <div
        style={{ marginLeft: '120px', marginTop: '16px', marginBottom: '16px' }}
      >
        八種競技得点表
      </div>

      {/* 上部のボタン部分の実装 */}
      <div style={{ display: 'flex', width: '360px', marginBottom: '10px' }}>
        <div style={{ marginLeft: '36px', width: '65%' }}>
          <HowToUse />
        </div>
        <Button
          onClick={reset}
          size="xs"
          style={{ color: 'black', backgroundColor: '#f5f5f5' }}
        >
          Reset
        </Button>
      </div>

      {/* 得点表部分の実装 */}
      {eventList.map((eN, index) => {
        const color = index % 2 === 0 ? '#f5f5f5' : '#ffffff';
        return (
          <div style={{ backgroundColor: color }} key={index}>
            <ScoreInput
              title={eventNameDict[eN]}
              record={records[eN]}
              point={points[eN]}
              handleScoreChange={handleChange(eN)}
              totalScore={calcTotalPoint(eN)}
              handlePointChange={handlePointChangeParent(eN)}
            />
          </div>
        );
      })}

      <div style={{ backgroundColor: '#f5f5f5' }}>
        <div style={{ marginLeft: '16px' }}>---総合---</div>
        <div
          style={{
            display: 'flex',
            width: '360px',
            textAlign: 'center',
          }}
        >
          <div style={{ width: '30%', marginLeft: '10pt' }}>
            1日目：{calcTotalPoint('A00')}
          </div>
          <div style={{ width: '30%', marginLeft: '10pt' }}>
            2日目：{calcTotalPoint('i500') - calcTotalPoint('A00')}
          </div>
          <div style={{ width: '20%', textAlign: 'right' }}>合計：</div>
          <div style={{ width: '20%', textAlign: 'right', marginRight: '1pt' }}>
            {calcTotalPoint('i500')} 点
          </div>
        </div>
      </div>

      {/* 下部のボタン部分の実装 */}
      <div style={{ marginTop: '10px', marginLeft: '20px' }}>
        <Flex justifyContent="space-between" maxWidth="300px">
          <Button size="xs" onClick={onOpenLoad}>
            Save/Load
          </Button>

          <Button
            onClick={reset}
            size="xs"
            style={{ color: 'black', backgroundColor: '#f5f5f5' }}
          >
            Reset
          </Button>
        </Flex>
      </div>

      {/* ロード用のモーダル */}
      <Modal isOpen={isOpenLoad} onClose={onCloseLoad}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            Save/Load
            <div style={{ fontSize: '10px' }}>
              ※１週間アクセスがないとデータが消える場合があります
            </div>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <div>
              <div>
                <Input
                  type="text"
                  value={inputName}
                  onChange={(e) => setInputName(e.target.value)}
                  // placeholder="例）2024年 日本選手権 予想"
                />
              </div>
              <div
                style={{
                  marginTop: '5px',
                  marginLeft: '10px',
                  marginRight: '10px',
                  display: 'flex',
                  justifyContent: 'space-between', // 要素の間にスペースを均等に配置
                  alignItems: 'center', // 要素を縦方向中央に配置
                }}
              >
                <div>
                  <HowToName />
                </div>

                <Button size="xs" onClick={saveData}>
                  名前を付けて保存
                </Button>
              </div>
              <br></br>
              <span style={{ fontSize: 12 }}>-データリスト-</span>
              <div>
                <UnorderedList>
                  {dataList.map((item, index) => (
                    <ListItem key={index} style={{ padding: '0px 0' }}>
                      {item}
                      &nbsp;
                      <Button
                        size="xs"
                        onClick={() => showSelectedData(item)}
                        style={{ marginRight: '5px', marginLeft: '5px' }}
                      >
                        Load
                      </Button>
                      &nbsp;
                      <Button size="xs" onClick={() => deleteData(item)}>
                        <DeleteIcon />
                      </Button>
                    </ListItem>
                  ))}
                </UnorderedList>
              </div>
            </div>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onCloseLoad}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Links />
    </div>
  );
};
