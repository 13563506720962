import { ExternalLinkIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Flex,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Text,
} from '@chakra-ui/react';
import React, { FC, ReactElement } from 'react';
import { heptathlonSingleRecord } from '../../common/types';
import { calcHeptathlonPointToJSX } from '../functions/calcHeptathlonPointForResult';
import { modalHeptathlonCreateShownData } from '../functions/createHeptathlonScoreData';
import { eventNameDict } from '../functions/eventNameDict';
import { shownResult } from '../functions/shownResult';
import { showDataInitial } from './constants/sbdatainitial';
import { profiles } from './ProfilesWoman';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  todayData: heptathlonSingleRecord[];
  CSB: heptathlonSingleRecord[];
  CPB: heptathlonSingleRecord[];
  SB: heptathlonSingleRecord[];
  PB: heptathlonSingleRecord[];
  Name: string;
};

export const IntroductionHeptathlonModal: FC<Props> = ({
  isOpen,
  onClose,
  todayData,
  CSB,
  CPB,
  SB,
  PB,
  Name,
}) => {
  const modalShowDataCreate = (data: heptathlonSingleRecord[]) => {
    const modalShowData = data.map((sa) => modalHeptathlonCreateShownData(sa));
    const clickNamedata = modalShowData.find((obj) => obj.name === Name);
    const clickNameshowdata =
      clickNamedata === undefined ? showDataInitial : clickNamedata;

    return clickNameshowdata;
  };

  const modalBodyDataBox = (
    title: ReactElement,
    data: heptathlonSingleRecord[],
    bgColor: string
  ) => {
    return (
      <Box flex="1" bg={bgColor} textAlign="center" color="black" fontSize="xs">
        <>{title}</>
        <br />
        {Object.entries(modalShowDataCreate(data)).map((a, i) => {
          if (i === 0) {
            return null;
          }
          if (i > 7) {
            return (
              <div key={i}>
                <Text fontSize={'3'} color={'GrayText'}>
                  <br />
                </Text>
                <Text fontWeight={'bold'}>{shownResult(a[1], a[0])}</Text>
                {/* <br /> */}
              </div>
            );
          }
          return (
            <div key={i}>
              <Text fontWeight={'bold'}>{shownResult(a[1], a[0])}</Text>
              {/* <br /> */}
              <Text fontSize={'12'} color={'GrayText'}>
                {calcHeptathlonPointToJSX(a[0], a[1])}
              </Text>
            </div>
          );
        })}
      </Box>
    );
  };

  // プロフィール名から適切なプロフィール情報をレンダリングする関数
  const modalProfile = (name: string) => {
    // 指定されたnameに一致するプロフィールを検索
    const profile = profiles.find((profile) => profile.name === name);

    // プロフィールが見つかった場合のレンダリング
    if (profile) {
      return (
        <>
          <Image src={profile.imageSrc} alt="Profile Image" width={'100%'} />
          {profile.description.split('\n').map((line, index) => (
            <React.Fragment key={index}>
              {line}
              <br />
            </React.Fragment>
          ))}
          <br />
          <SimpleGrid columns={2} spacing={5}>
            {profile.socialMedia.map((social, index) => (
              <Box key={index} height="7px">
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Image
                    src={social.icon}
                    alt={social.platform}
                    width={'16px'}
                  />
                  <a href={social.url} style={{ marginLeft: '5px' }}>
                    {social.handle}
                  </a>
                  <ExternalLinkIcon />
                </div>
              </Box>
            ))}
          </SimpleGrid>
          <br />
        </>
      );
    } else {
      // プロフィールが見つからない場合
      return 'Coming Soon...';
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        {/* <ModalHeader>{todayData?.name.split('-')[0] || ''}</ModalHeader> */}
        <ModalHeader>{Name.split('-')[0] || ''}</ModalHeader>

        <ModalCloseButton />
        <ModalBody>
          <Box>
            <Box fontWeight={'bold'}>Profile</Box>
            {modalProfile(Name)}
          </Box>
          <br />
          <Box fontWeight={'bold'}>Record Data</Box>

          <Flex color="white">
            <Box
              fontSize="xs"
              flex="1"
              // bg="gray.100"
              bg="white"
              textAlign="center"
              color="black"
            >
              <>
                <br />
                種目
              </>
              <br />
              {Object.entries(modalShowDataCreate(todayData)).map((a, i) => {
                if (i === 0) {
                  return null;
                }
                if (i > 7) {
                  return (
                    <div key={i}>
                      <Text fontSize={'3'}>
                        <br />
                      </Text>
                      {eventNameDict(a[0])}
                      <br />
                    </div>
                  );
                }
                return (
                  <div key={i}>
                    {eventNameDict(a[0])}
                    <br />
                    <Text fontSize={'12'}>
                      <br />
                    </Text>
                  </div>
                );
              })}
            </Box>
            {modalBodyDataBox(
              <>
                今日の <br />
                記録
              </>,
              todayData,
              // 'white'
              'gray.100'
            )}
            {modalBodyDataBox(
              <>
                SB時の <br />
                内訳
              </>,
              CSB,
              'white'

              // 'gray.100'
            )}
            {modalBodyDataBox(
              <>
                PB時の <br />
                内訳
              </>,
              CPB,
              // 'white'
              'gray.100'
            )}
            {modalBodyDataBox(
              <>
                各種目の <br />
                SB
              </>,
              SB,
              'white'

              // 'gray.100'
            )}
            {modalBodyDataBox(
              <>
                各種目の <br />
                PB
              </>,
              PB,
              // 'white'
              'gray.100'
            )}
          </Flex>
        </ModalBody>

        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={onClose}>
            とじる
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
