import React, { useState } from "react";
// import "/App.css";
import { ChakraProvider } from "@chakra-ui/react";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
} from "@chakra-ui/react";
import { Tabs, TabList, TabPanels, Tab, TabPanel } from "@chakra-ui/react";
import { Button } from "@chakra-ui/react";
import { getMyInfo, getShimizuInfo } from "../request/requests";
import { Link } from "react-router-dom";

export function DemoDecathlonRankingTable() {
  const [myInfo, setMyInfo] = useState<string[]>([]);
  const handleClick = () => {
    console.log("clicked");
    getMyInfo().then((response) => {
      console.log(response);
      const newInfo = [...myInfo, response.data];
      setMyInfo(newInfo);
    });
  };

  const handleShimizuClick = () => {
    getShimizuInfo().then((response) => {
      console.log(response);
      const newInfo = [...myInfo, response.data];
      setMyInfo(newInfo);
    });
  };

  return (
    <>
      <ChakraProvider>
        <Link to={"/decathloninput"}>input</Link>
        <Button colorScheme="blue" onClick={handleClick}>
          myinfo
        </Button>
        <Button colorScheme="blue" onClick={handleShimizuClick}>
          shimizu
        </Button>
        <div className="App">DecathlonGameDateBase</div>
        <Tabs variant="soft-rounded" colorScheme="green">
          <TabList>
            <Tab>総合順位</Tab>
            <Tab>予想順位(SB換算)</Tab>
            <Tab>予想順位(PB換算)</Tab>
            <Tab>100m</Tab>
            <Tab>走幅跳</Tab>
            <Tab>砲丸投</Tab>
            <Tab>走高跳</Tab>
            <Tab>400m</Tab>
            <Tab>110mH</Tab>
            <Tab>円盤投</Tab>
            <Tab>棒高跳</Tab>
            <Tab>やり投</Tab>
            <Tab>1500m</Tab>
          </TabList>
          <TabPanels>
            {/* 総合順位 */}
            <TabPanel>
              NEXT →→→ 110mH
              <TableContainer>
                <Table size="sm">
                  <TableCaption>DecathlonScoreRanking</TableCaption>
                  <Thead>
                    <Tr>
                      <Th>順位</Th>
                      <Th>名前</Th>
                      <Th>総合</Th>
                      <Th>100m</Th>
                      <Th>走幅跳</Th>
                      <Th>砲丸投</Th>
                      <Th>走高跳</Th>
                      <Th>400m</Th>
                      <Th>110mH</Th>
                      <Th>円盤投</Th>
                      <Th>棒高跳</Th>
                      <Th>やり投</Th>
                      <Th>1500m</Th>
                      <Th>1日目</Th>
                      <Th>2日目</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    <Tr>
                      <Td>1</Td>
                      <Td>山田太郎</Td>
                      <Td>5000点</Td>
                      <Td>
                        10.00
                        <br />
                        (1000)
                      </Td>
                      <Td>
                        8.00
                        <br />
                        (1000)
                      </Td>
                      <Td>
                        15.00
                        <br />
                        (999)
                      </Td>
                      <Td>
                        2.00
                        <br />
                        (1000)
                      </Td>
                      <Td>
                        48.00
                        <br />
                        (1000)
                      </Td>
                      <Td>-</Td>
                      <Td>-</Td>
                      <Td>-</Td>
                      <Td>-</Td>
                      <Td>-</Td>
                      <Td>5000点</Td>
                      <Td>-</Td>
                    </Tr>
                    <Tr>
                      <Td>2</Td>
                      <Td>佐藤次郎</Td>
                      <Td>4000点</Td>

                      <Td>10.40</Td>
                      <Td>8.00</Td>
                      <Td>14.00</Td>
                      <Td>2.00</Td>
                      <Td>49.00</Td>
                      <Td>-</Td>
                      <Td>-</Td>
                      <Td>-</Td>
                      <Td>-</Td>
                      <Td>-</Td>
                      <Td>4000点</Td>
                      <Td>-点</Td>
                    </Tr>
                    {myInfo.map((info, idx) => {
                      return (
                        <Tr key={idx}>
                          <Td>{3 + idx}</Td>
                          <Td>{info}</Td>
                          <Td>8999点</Td>
                          <Td>10.00</Td>
                          <Td>8.00</Td>
                          <Td>15.00</Td>
                          <Td>2.00</Td>
                          <Td>48.00</Td>
                          <Td>14.00</Td>
                          <Td>48.00</Td>
                          <Td>5.00</Td>
                          <Td>68.00</Td>
                          <Td>4.28.00</Td>
                          <Td>5000点</Td>
                          <Td>5000点</Td>
                        </Tr>
                      );
                    })}
                  </Tbody>
                </Table>
              </TableContainer>
            </TabPanel>

            {/* 予想順位SB */}
            <TabPanel>
              110mH以降の記録はSBで換算した場合の順位です
              <br />
              NEXT →→→ 110mH
              <TableContainer>
                <Table size="sm">
                  <TableCaption>DecathlonScoreRanking</TableCaption>
                  <Thead>
                    <Tr>
                      <Th>順位</Th>
                      <Th>名前</Th>
                      <Th>総合</Th>
                      <Th>100m</Th>
                      <Th>走幅跳</Th>
                      <Th>砲丸投</Th>
                      <Th>走高跳</Th>
                      <Th>400m</Th>
                      <Th>110mH</Th>
                      <Th>円盤投</Th>
                      <Th>棒高跳</Th>
                      <Th>やり投</Th>
                      <Th>1500m</Th>
                      <Th>1日目</Th>
                      <Th>2日目</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    <Tr>
                      <Td>1</Td>
                      <Td>山田太郎</Td>

                      <Td>9000点</Td>
                      <Td>
                        10.00
                        <br />
                        (1000)
                      </Td>
                      <Td>
                        8.00
                        <br />
                        (1000)
                      </Td>
                      <Td>
                        15.00
                        <br />
                        (999)
                      </Td>
                      <Td>
                        2.00
                        <br />
                        (1000)
                      </Td>
                      <Td>
                        48.00
                        <br />
                        (1000)
                      </Td>
                      <Td>
                        14.00
                        <br />
                        (1000)
                      </Td>
                      <Td>
                        48.00
                        <br />
                        (1000)
                      </Td>
                      <Td>
                        5.00
                        <br />
                        (1000)
                      </Td>
                      <Td>
                        68.00
                        <br />
                        (1000)
                      </Td>
                      <Td>
                        4.28.00
                        <br />
                        (1000)
                      </Td>
                      <Td>4500点</Td>
                      <Td>4500点</Td>
                    </Tr>
                    <Tr>
                      <Td>2</Td>
                      <Td>佐藤次郎</Td>
                      <Td>8999点</Td>
                      <Td>10.00</Td>
                      <Td>8.00</Td>
                      <Td>15.00</Td>
                      <Td>2.00</Td>
                      <Td>48.00</Td>
                      <Td>14.00</Td>
                      <Td>48.00</Td>
                      <Td>5.00</Td>
                      <Td>68.00</Td>
                      <Td>4.28.00</Td>
                      <Td>5000点</Td>
                      <Td>5000点</Td>
                    </Tr>
                  </Tbody>
                </Table>
              </TableContainer>{" "}
            </TabPanel>

            {/* 予想順位PB */}
            <TabPanel>
              110mH以降の記録はPBで換算した場合の順位です
              <br />
              NEXT →→→ 110mH{" "}
              <TableContainer>
                <Table size="sm">
                  <TableCaption>DecathlonScoreRanking</TableCaption>
                  <Thead>
                    <Tr>
                      <Th>順位</Th>
                      <Th>名前</Th>
                      <Th>総合</Th>
                      <Th>100m</Th>
                      <Th>走幅跳</Th>
                      <Th>砲丸投</Th>
                      <Th>走高跳</Th>
                      <Th>400m</Th>
                      <Th>110mH</Th>
                      <Th>円盤投</Th>
                      <Th>棒高跳</Th>
                      <Th>やり投</Th>
                      <Th>1500m</Th>
                      <Th>1日目</Th>
                      <Th>2日目</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    <Tr>
                      <Td>1</Td>
                      <Td>佐藤次郎</Td>

                      <Td>9990点</Td>
                      <Td>
                        10.00
                        <br />
                        (1000)
                      </Td>
                      <Td>
                        8.00
                        <br />
                        (1000)
                      </Td>
                      <Td>
                        15.00
                        <br />
                        (999)
                      </Td>
                      <Td>
                        2.00
                        <br />
                        (1000)
                      </Td>
                      <Td>
                        48.00
                        <br />
                        (1000)
                      </Td>
                      <Td>
                        14.00
                        <br />
                        (1000)
                      </Td>
                      <Td>
                        48.00
                        <br />
                        (1000)
                      </Td>
                      <Td>
                        5.00
                        <br />
                        (1000)
                      </Td>
                      <Td>
                        68.00
                        <br />
                        (1000)
                      </Td>
                      <Td>
                        4.28.00
                        <br />
                        (1000)
                      </Td>
                      <Td>5000点</Td>
                      <Td>5000点</Td>
                    </Tr>
                    <Tr>
                      <Td>2</Td>
                      <Td>山田太郎</Td>
                      <Td>9900点</Td>
                      <Td>10.00</Td>
                      <Td>8.00</Td>
                      <Td>15.00</Td>
                      <Td>2.00</Td>
                      <Td>48.00</Td>
                      <Td>14.00</Td>
                      <Td>48.00</Td>
                      <Td>5.00</Td>
                      <Td>68.00</Td>
                      <Td>4.28.00</Td>
                      <Td>5000点</Td>
                      <Td>5000点</Td>
                    </Tr>
                  </Tbody>
                </Table>
              </TableContainer>{" "}
            </TabPanel>

            {/* １００m */}
            <TabPanel>
              <TableContainer>
                <Table size="sm">
                  <TableCaption>DecathlonScoreRanking</TableCaption>
                  <Thead>
                    <Tr>
                      <Th>順位</Th>
                      <Th>名前</Th>
                      <Th>SB</Th>
                      <Th>PB</Th>
                      <Th>結果</Th>
                      <Th>合計得点</Th>
                      <Th>
                        予想得点
                        <br />
                        (SB換算)
                      </Th>
                      <Th>
                        予想得点
                        <br />
                        (PB換算)
                      </Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    <Tr>
                      <Td>1</Td>
                      <Td>山田太郎</Td>
                      <Td>
                        11.00
                        <br />
                        (800)
                      </Td>
                      <Td>
                        10.50
                        <br />
                        (890)
                      </Td>
                      <Td>
                        10.00
                        <br />
                        (1000)
                      </Td>
                      <Td>1000点</Td>
                      <Td>8000点</Td>
                      <Td>9000点</Td>
                    </Tr>
                    <Tr>
                      <Td>2</Td>
                      <Td>佐藤次郎</Td>
                      <Td>
                        11.00
                        <br />
                        (800)
                      </Td>
                      <Td>
                        10.50
                        <br />
                        (850)
                      </Td>
                      <Td>
                        10.40
                        <br />
                        (900)
                      </Td>
                      <Td>900点</Td>
                      <Td>8000点</Td>
                      <Td>9000点</Td>
                    </Tr>
                  </Tbody>
                </Table>
              </TableContainer>
            </TabPanel>

            {/* longjump */}

            <TabPanel>
              <TableContainer>
                <Table size="sm">
                  <TableCaption>DecathlonScoreRanking</TableCaption>
                  <Thead>
                    <Tr>
                      <Th>順位</Th>
                      <Th>名前</Th>
                      <Th>SB</Th>
                      <Th>PB</Th>
                      <Th>結果</Th>
                      <Th>合計得点</Th>
                      <Th>
                        最終得点
                        <br />
                        (SB換算)
                      </Th>
                      <Th>
                        最終得点
                        <br />
                        (PB換算)
                      </Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    <Tr>
                      <Td>1</Td>
                      <Td>山田太郎</Td>
                      <Td>
                        7.00
                        <br />
                        (800)
                      </Td>
                      <Td>
                        7.50
                        <br />
                        (890)
                      </Td>
                      <Td>
                        8.00
                        <br />
                        (1000)
                      </Td>
                      <Td>2000点</Td>
                      <Td>8000点</Td>
                      <Td>9000点</Td>
                    </Tr>
                    <Tr>
                      <Td>2</Td>
                      <Td>佐藤次郎</Td>
                      <Td>
                        7.00
                        <br />
                        (800)
                      </Td>
                      <Td>
                        7.50
                        <br />
                        (890)
                      </Td>
                      <Td>
                        7.40
                        <br />
                        (900)
                      </Td>
                      <Td>1800点</Td>
                      <Td>8000点</Td>
                      <Td>9000点</Td>
                    </Tr>
                  </Tbody>
                </Table>
              </TableContainer>
            </TabPanel>

            {/* 砲丸投 */}
            <TabPanel>
              <TableContainer>
                <Table size="sm">
                  <TableCaption>DecathlonScoreRanking</TableCaption>
                  <Thead>
                    <Tr>
                      <Th>順位</Th>
                      <Th>名前</Th>
                      <Th>SB</Th>
                      <Th>PB</Th>
                      <Th>結果</Th>
                      <Th>合計得点</Th>
                      <Th>
                        最終得点
                        <br />
                        (SB換算)
                      </Th>
                      <Th>
                        最終得点
                        <br />
                        (PB換算)
                      </Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    <Tr>
                      <Td>1</Td>
                      <Td>山田太郎</Td>
                      <Td>
                        13.00
                        <br />
                        (800)
                      </Td>
                      <Td>
                        14.50
                        <br />
                        (890)
                      </Td>
                      <Td>-</Td>
                      <Td>2000点</Td>
                      <Td>8000点</Td>
                      <Td>9000点</Td>
                    </Tr>
                    <Tr>
                      <Td>2</Td>
                      <Td>佐藤次郎</Td>
                      <Td>
                        12.00
                        <br />
                        (700)
                      </Td>
                      <Td>
                        14.50
                        <br />
                        (890)
                      </Td>
                      <Td>-</Td>
                      <Td>1800点</Td>
                      <Td>8000点</Td>
                      <Td>9000点</Td>
                    </Tr>
                  </Tbody>
                </Table>
              </TableContainer>
            </TabPanel>

            {/* 走高跳 */}
            <TabPanel>
              <TableContainer>
                <Table size="sm">
                  <TableCaption>DecathlonScoreRanking</TableCaption>
                  <Thead>
                    <Tr>
                      <Th>順位</Th>
                      <Th>名前</Th>
                      <Th>持ち記録</Th>
                      <Th>予想得点(SB換算)</Th>
                      <Th>予想得点(PB換算)</Th>

                      <Th>結果</Th>
                      {/* <Th>1回目</Th>
                      <Th>2回目</Th>
                      <Th>3回目</Th> */}
                    </Tr>
                  </Thead>
                  <Tbody>
                    <Tr>
                      <Td>1</Td>
                      <Td>山田太郎</Td>
                      <Td>
                        SB 11.00
                        <br />
                        PB 10.50
                      </Td>
                      <Td>8000点</Td>
                      <Td>9000点</Td>
                      <Td>
                        10.00
                        <br />
                        (1000)
                      </Td>
                    </Tr>
                    <Tr>
                      <Td>2</Td>
                      <Td>佐藤次郎</Td>
                      <Td>
                        SB 11.00
                        <br />
                        PB 10.50
                      </Td>
                      <Td>8000点</Td>
                      <Td>9000点</Td>

                      <Td>10.40</Td>
                    </Tr>
                  </Tbody>
                </Table>
              </TableContainer>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </ChakraProvider>
    </>
  );
}
