import { shownDataType, singleRecord } from '../../common/types';
import React, { FC, memo, useEffect, useState } from 'react';
import '../../App.css';
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Button,
  Box,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { calcPoint, calcPointToJSX } from '../functions/calcPointForResult';
import { UpDownIcon } from '@chakra-ui/icons';
import { createShownData } from '../functions/createScoreData';
import { eventNameDict } from '../functions/eventNameDict';
import { showEventNameType } from '../../common/types';
import { shownResult } from '../functions/shownResult';
import { explanationButton } from './explanationButton';
import { IntroductionModal } from './IntroductionModal';

type ResultTableProps = {
  Data: singleRecord[];
  rawData: singleRecord[];
  title: string;
  csbData: singleRecord[];
  cpbData: singleRecord[];
  sbData: singleRecord[];
  pbData: singleRecord[];
};

export const ResultTable: FC<ResultTableProps> = memo(
  ({ Data, rawData, title = [], csbData, cpbData, sbData, pbData }) => {
    const [data, setData] = useState(Data);

    const [currentSort, setCurrentSort] = useState<showEventNameType>('total');
    const rank = Object.keys(data);

    const [clickName, setClickName] = useState('');

    const showData = data.map((s) => createShownData(s));
    const differenceCsbData = csbData.map((ss) => createShownData(ss));

    const differenceCpbData = cpbData.map((sss) => createShownData(sss));

    const keys = Object.keys(showData[0]) as showEventNameType[];

    const filledProfileNameList = Data.filter((f) => !!f['name']).map(
      (f) => f.name
    );
    // console.log(filledProfileNameList);
    const { isOpen, onOpen, onClose } = useDisclosure();

    //データをとってくる処理

    useEffect(() => {
      const showData = Data.map((s) => createShownData(s));
      setData(
        showData.sort((a, b) => {
          return a.total > b.total ? -1 : 1;
        })
      );
    }, [Data]);

    //ソートボタンの実装
    const sortButton = (EventName: showEventNameType) => {
      if (['name'].includes(EventName)) {
        return <>名前</>;
      }
      return (
        <Button size="xs" onClick={() => handlePointChange(EventName)}>
          {eventNameDict(EventName)}
          <UpDownIcon />
        </Button>
      );
    };

    const handlePointChange = (EventName: showEventNameType) => {
      let newData = [...showData];
      setCurrentSort(EventName);
      const sortData = newData.sort((a, b) => {
        return calcPoint(EventName, a[EventName]) >
          calcPoint(EventName, b[EventName])
          ? -1
          : 1;
      });
      setData(sortData);
    };

    const handleNameClick = (sd: shownDataType, NAME: string) => () => {
      // const clickNameSbdata = showDataCSB.find((obj) => obj.name === NAME);
      // setResult(sd);
      setClickName(NAME);
      // setCpb(sd);

      onOpen();
    };

    return (
      <>
        <IntroductionModal
          isOpen={isOpen}
          onClose={onClose}
          todayData={rawData}
          CSB={csbData}
          CPB={cpbData}
          SB={sbData}
          PB={pbData}
          Name={clickName}
        />
        <Box ml="1" fontFamily="monospace" position="relative" zIndex="40">
          　　　{title}
          　　　{explanationButton(title)}
        </Box>

        <TableContainer mt={'2'}>
          <Table size="sm" width="1">
            <TableCaption>DecathlonResult</TableCaption>
            <Thead>
              <Tr>
                <Th textAlign={'center'}>
                  　順位　
                  <br />
                  {eventNameDict(currentSort)}
                </Th>
                {keys.map((EvName: showEventNameType, i) => {
                  return (
                    <Th key={i} textAlign={'center'}>
                      {sortButton(EvName)}
                    </Th>
                  );
                })}
              </Tr>
            </Thead>
            <Tbody>
              {showData.map((sd: shownDataType, i) => {
                // console.log("sd", sd);
                return (
                  <Tr key={i}>
                    <Td textAlign={'center'}>{Number(rank[i]) + 1}</Td>
                    <Td textAlign={'center'}>
                      {(filledProfileNameList.includes(sd.name) && (
                        <>
                          <Text
                            fontSize={'9.5pt'}
                            fontWeight={'bold'}
                            color={'#4169e1'}
                            onClick={handleNameClick(sd, sd.name)}
                          >
                            {sd.name.split('-')[0] || ''}
                          </Text>
                        </>
                      )) || (
                        <Text fontSize={'9.5pt'} fontWeight={'bold'}>
                          {sd.name.split('-')[0] || ''}
                        </Text>
                      )}
                      <Text fontSize={'7pt'} color={'GrayText'}>
                        {sd.name.split('-')[1] || 'なし'}
                      </Text>
                    </Td>
                    {Object.entries(sd).map((row, i) => {
                      const kiroku = row[1];
                      const point = calcPointToJSX(row[0], row[1]);

                      const BestData =
                        title === '予想順位(SB換算)'
                          ? differenceCsbData
                          : title === '予想順位(PB換算)'
                          ? differenceCpbData
                          : showData;

                      const differenceData = BestData.find(
                        (obj) => obj.name === sd.name
                      );

                      const totalData = Number(differenceData?.total)
                        ? Number(differenceData?.total)
                        : 0;
                      const difference = Number(sd.total) - totalData;
                      // const difference =
                      //   Number(sd.total) - Number(differenceData?.total);

                      const differenceColor =
                        difference >= 0 ? '#ff7f50' : '#87cefa';

                      if (row[0] === 'name') {
                        return null;
                      }
                      return (
                        <Td key={i} textAlign={'center'}>
                          {shownResult(kiroku, row[0])}
                          {row[0] !== 'name' ? <br /> : <></>}
                          {row[0] === 'total' ? (
                            <>
                              {title === '総合順位' ||
                              title === 'SB順位' ||
                              title === 'PB順位' ? null : (
                                <Text color={differenceColor}>
                                  {difference > 0
                                    ? '+' + difference
                                    : difference === 0
                                    ? '±0'
                                    : difference}
                                </Text>
                              )}
                            </>
                          ) : (
                            <>{point}</>
                          )}
                        </Td>
                      );
                    })}
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </TableContainer>
      </>
    );
  }
);
