import { Text, Box } from '@chakra-ui/react';
import { Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react';
import { Card, Heading, CardBody, StackDivider, Stack } from '@chakra-ui/react';
import {
  DecathlonEventList,
  HeptathlonEventList,
  OctathlonEventList,
  TetraManEventList,
  TetraWomanEventList,
} from '../common/eventLists';
import { eventNameDict } from '../page_calculator/components/eventNameDict';
import { Links } from '../page_calculator/components/links';
import {
  DecaPointgaps,
  HepPointgaps,
  OctaPointgaps,
  TetraManPointgaps,
  TetraWomanPointgaps,
} from '../common/pointGaps';

export const PointGapsList = () => {
  const title = 'あの選手との差はどれくらい？';
  const GapBox = (Eventname: string, Gap: JSX.Element) => {
    return (
      <Box>
        <Heading size="xs" textTransform="uppercase">
          {Eventname}
        </Heading>
        <Text pt="2" fontSize="sm">
          {Gap}
        </Text>
      </Box>
    );
  };

  return (
    <>
      {/* <CustomHeader /> */}
      <Box ml="16px" mt="16px">
        <Tabs variant="enclosed">
          <TabList>
            <Tab>１０種</Tab>
            <Tab>７種</Tab>
            <Tab>８種</Tab>
            <Tab>４種(男子)</Tab>
            <Tab>４種(女子)</Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <Text fontSize="1xl">{title}</Text>
              <Card>
                <CardBody>
                  <Stack divider={<StackDivider />} spacing="4">
                    <Box>
                      <Heading size="md">~1日目~</Heading>
                    </Box>
                    {DecathlonEventList.map((EventName, index) => {
                      const title = eventNameDict[EventName];
                      if (index < 5) {
                        return (
                          <div key={index}>
                            {' '}
                            {GapBox(title, DecaPointgaps[EventName])}
                          </div>
                        );
                      }
                      return null;
                    })}
                  </Stack>
                </CardBody>
              </Card>
              <Card style={{ marginTop: '16px' }}>
                <CardBody>
                  <Stack divider={<StackDivider />} spacing="4">
                    <Box>
                      <Heading size="md">~2日目~</Heading>
                    </Box>
                    {DecathlonEventList.map((EventName, index) => {
                      const title = eventNameDict[EventName];
                      if (index >= 5) {
                        return (
                          <div key={index}>
                            {' '}
                            {GapBox(title, DecaPointgaps[EventName])}
                          </div>
                        );
                      }
                      return null;
                    })}
                  </Stack>
                </CardBody>
              </Card>
            </TabPanel>

            <TabPanel>
              <Text fontSize="1xl">{title}</Text>
              <Card>
                <CardBody>
                  <Stack divider={<StackDivider />} spacing="4">
                    <Box>
                      <Heading size="md">~1日目~</Heading>
                    </Box>
                    {HeptathlonEventList.map((EventName, index) => {
                      const title = eventNameDict[EventName];
                      if (index < 4) {
                        return (
                          <div key={index}>
                            {' '}
                            {GapBox(title, HepPointgaps[EventName])}
                          </div>
                        );
                      }
                      return null;
                    })}
                    <Box>
                      <Heading size="md">~2日目~</Heading>
                    </Box>
                    {HeptathlonEventList.map((EventName, index) => {
                      const title = eventNameDict[EventName];
                      if (index >= 4) {
                        return (
                          <div key={index}>
                            {' '}
                            {GapBox(title, HepPointgaps[EventName])}
                          </div>
                        );
                      }
                      return null;
                    })}
                  </Stack>
                </CardBody>
              </Card>
            </TabPanel>

            <TabPanel>
              <Text fontSize="1xl">{title}</Text>
              <Card>
                <CardBody>
                  <Stack divider={<StackDivider />} spacing="4">
                    <Box>
                      <Heading size="md">~1日目~</Heading>
                    </Box>
                    {OctathlonEventList.map((EventName, index) => {
                      const title = eventNameDict[EventName];
                      if (index < 4) {
                        return (
                          <div key={index}>
                            {' '}
                            {GapBox(title, OctaPointgaps[EventName])}
                          </div>
                        );
                      }
                      return null;
                    })}
                    <Box>
                      <Heading size="md">~2日目~</Heading>
                    </Box>
                    {OctathlonEventList.map((EventName, index) => {
                      const title = eventNameDict[EventName];
                      if (index >= 4) {
                        return (
                          <div key={index}>
                            {' '}
                            {GapBox(title, OctaPointgaps[EventName])}
                          </div>
                        );
                      }
                      return null;
                    })}
                  </Stack>
                </CardBody>
              </Card>
            </TabPanel>

            <TabPanel>
              <Text fontSize="1xl">{title}</Text>
              <Card>
                <CardBody>
                  <Stack divider={<StackDivider />} spacing="4">
                    {TetraManEventList.map((EventName, index) => {
                      const title = eventNameDict[EventName];
                      if (index < 4) {
                        return (
                          <div key={index}>
                            {' '}
                            {GapBox(title, TetraManPointgaps[EventName])}
                          </div>
                        );
                      }
                      return null;
                    })}
                  </Stack>
                </CardBody>
              </Card>
            </TabPanel>

            <TabPanel>
              <Text fontSize="1xl">{title}</Text>
              <Card>
                <CardBody>
                  <Stack divider={<StackDivider />} spacing="4">
                    {TetraWomanEventList.map((EventName, index) => {
                      const title = eventNameDict[EventName];
                      if (index < 4) {
                        return (
                          <div key={index}>
                            {' '}
                            {GapBox(title, TetraWomanPointgaps[EventName])}
                          </div>
                        );
                      }
                      return null;
                    })}
                  </Stack>
                </CardBody>
              </Card>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
      <Links />
    </>
  );
};
