// ResettableModal.tsx
import React, { useState } from 'react';
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Input,
  Text,
} from '@chakra-ui/react';

export const ResettableModal: React.FC = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [number, setNumber] = useState<number>(10); // 現在の数字
  const [tempNumber, setTempNumber] = useState<number>(10); // 一時的な編集用の数字
  const [isEditing, setIsEditing] = useState<boolean>(false); // 編集モードの管理

  const handleEdit = () => {
    setIsEditing(true);
    setTempNumber(number); // 編集開始時に一時的な数字を現在の数字に設定
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTempNumber(parseInt(event.target.value, 10));
  };

  const handleSave = () => {
    setNumber(tempNumber); // 保存ボタンで本番の数字を更新
    setIsEditing(false);
  };

  const handleCancel = () => {
    setIsEditing(false); // 編集をキャンセルし、編集前の状態に戻る
    onClose(); // モーダルを閉じる
  };

  const handleClose = () => {
    setIsEditing(false); // 編集状態をリセット
    setTempNumber(number); // 一時的な数字を元の数字に戻す
    onClose(); // モーダルを閉じる
  };

  return (
    <>
      <Button onClick={onOpen}>Open Modal</Button>

      <Modal isOpen={isOpen} onClose={handleClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Editable Number</ModalHeader>
          <ModalCloseButton onClick={handleClose} />
          <ModalBody>
            {isEditing ? (
              <Input
                type="number"
                value={tempNumber}
                onChange={handleInputChange}
                placeholder="Enter number"
              />
            ) : (
              <Text fontSize="xl">Current Number: {number}</Text>
            )}
          </ModalBody>
          <ModalFooter>
            {isEditing ? (
              <>
                <Button colorScheme="blue" onClick={handleSave}>
                  Save
                </Button>
                <Button colorScheme="red" ml={3} onClick={handleCancel}>
                  Cancel
                </Button>
              </>
            ) : (
              <Button colorScheme="blue" onClick={handleEdit}>
                Edit
              </Button>
            )}
            <Button colorScheme="gray" ml={3} onClick={handleClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ResettableModal;
