import {
  heptathloEventNameType,
  heptathlonSingleRecord,
} from '../../common/types';
import { calcHeptathlonPoint } from './calcHeptathlonPointForResult';

const eventKeysWithoutName = ['i00h', 'hj', 'sp', 'z00', 'lj', 'jt', 'B00'];

const calcTotalPoint = (IniData: any) => {
  let totalpoint = 0;
  for (const EventName of eventKeysWithoutName) {
    const score = calcHeptathlonPoint(EventName, IniData[EventName]);
    totalpoint += score;
  }
  return totalpoint;
};

const calcTotalPointByEvent = (title: heptathloEventNameType, IniData: any) => {
  let totalpoint = 0;
  for (const EventName of eventKeysWithoutName) {
    const score = calcHeptathlonPoint(EventName, IniData[EventName]);
    totalpoint += score;

    if (EventName === title) {
      break;
    }
  }
  return totalpoint;
};

const calcDay1Point = (IniData: any) => {
  let day1point = 0;
  for (const EventName of eventKeysWithoutName) {
    const score = calcHeptathlonPoint(EventName, IniData[EventName]);
    day1point += score;
    if (EventName === 'z00') {
      break;
    }
  }
  return day1point;
};

export const createHeptathlonShownData = (data: heptathlonSingleRecord) => {
  const totalPoint = calcTotalPoint(data);
  const day1Point = calcDay1Point(data);
  const day2Point = calcTotalPoint(data) - calcDay1Point(data);
  return {
    name: data.name,
    total: String(totalPoint),
    i00h: data.i00h,
    hj: data.hj,
    sp: data.sp,
    z00: data.z00,
    lj: data.lj,
    jt: data.jt,
    B00: data.B00,
    firstDay: String(day1Point),
    secondDay: String(day2Point),
  };
};

export const modalHeptathlonCreateShownData = (
  data: heptathlonSingleRecord
) => {
  const totalPoint = calcTotalPoint(data);
  const day1Point = calcDay1Point(data);
  const day2Point = calcTotalPoint(data) - calcDay1Point(data);
  return {
    name: data.name,
    i00h: data.i00h,
    hj: data.hj,
    sp: data.sp,
    z00: data.z00,
    lj: data.lj,
    jt: data.jt,
    B00: data.B00,
    firstDay: String(day1Point),
    secondDay: String(day2Point),
    total: String(totalPoint),
  };
};

export const createHeptathlonForecastData = (
  currentData: heptathlonSingleRecord,
  bestData: heptathlonSingleRecord
) => {
  return {
    name: currentData.name,
    i00h: currentData.i00h === '' ? bestData.i00h : currentData.i00h,
    hj: currentData.hj === '' ? bestData.hj : currentData.hj,
    sp: currentData.sp === '' ? bestData.sp : currentData.sp,
    z00: currentData.z00 === '' ? bestData.z00 : currentData.z00,
    lj: currentData.lj === '' ? bestData.lj : currentData.lj,
    jt: currentData.jt === '' ? bestData.jt : currentData.jt,
    B00: currentData.B00 === '' ? bestData.B00 : currentData.B00,
  };
};

export const createHeptathlonEventForecastData = (
  title: heptathloEventNameType,
  currentData: heptathlonSingleRecord,
  bestData: heptathlonSingleRecord
) => {
  return {
    name: currentData.name,
    i00h:
      currentData.i00h &&
      ['i00h', 'hj', 'sp', 'z00', 'lj', 'jt', 'B00'].includes(title)
        ? currentData.i00h
        : bestData.i00h,
    hj:
      currentData.hj && ['hj', 'sp', 'z00', 'lj', 'jt', 'B00'].includes(title)
        ? currentData.hj
        : bestData.hj,
    sp:
      currentData.sp && ['sp', 'z00', 'lj', 'jt', 'B00'].includes(title)
        ? currentData.sp
        : bestData.sp,
    z00:
      currentData.z00 && ['z00', 'lj', 'jt', 'B00'].includes(title)
        ? currentData.z00
        : bestData.z00,
    lj:
      currentData.lj && ['lj', 'jt', 'B00'].includes(title)
        ? currentData.lj
        : bestData.lj,
    jt:
      currentData.jt && ['jt', 'B00'].includes(title)
        ? currentData.jt
        : bestData.jt,
    B00:
      currentData.B00 && ['B00'].includes(title)
        ? currentData.B00
        : bestData.B00,
  };
};

export const createHeptathlonEventShownData = (
  title: heptathloEventNameType,
  resultdata: heptathlonSingleRecord,
  sbDataInitial: heptathlonSingleRecord,
  pbDataInitial: heptathlonSingleRecord,
  cSB: heptathlonSingleRecord,
  cPB: heptathlonSingleRecord
) => {
  const sbForecastData = createHeptathlonEventForecastData(
    title,
    resultdata,
    cSB
  );
  const pbForecastData = createHeptathlonEventForecastData(
    title,
    resultdata,
    cPB
  );
  return {
    name: resultdata.name,
    result: resultdata[title],
    sb: sbDataInitial[title],
    pb: pbDataInitial[title],
    total: String(calcTotalPointByEvent(title, resultdata)),
    sbTotal: String(calcTotalPoint(sbForecastData)),
    pbTotal: String(calcTotalPoint(pbForecastData)),
  };
};
