import { Button } from '@chakra-ui/react';
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverArrow,
  PopoverCloseButton,
} from '@chakra-ui/react';

// type explanationType = "総合順位" | "予想順位(SB換算)" | "予想順位(PB換算)";

export const explanationButton = (event: string | never[]) => {
  const explanation = () => {
    if (event === '総合順位') {
      return <>終了種目までの結果です</>;
    }
    if (event === '予想順位(SB換算)') {
      return (
        <>
          終了種目までの結果とシーズンベスト(SB)を組み合わせて最終得点を予想しています
        </>
      );
    }
    if (event === '予想順位(PB換算)') {
      return (
        <>
          終了種目までの結果と自己ベスト(PB)を組み合わせて最終得点を予想しています
        </>
      );
    }
    if (event === 'PB順位') {
      return <>自己ベスト(PB)の内訳です</>;
    }
    if (event === 'SB順位') {
      return <>シーズンベスト(SB)の内訳です</>;
    }
  };

  // {
  //   総合順位: <>終了種目までの結果です</>,
  //   "予想順位(SB換算)": (
  //     <>
  //       終了種目までの結果とシーズンベスト(SB)を組み合わせて最終得点を計算しています
  //     </>
  //   ),
  //   "予想順位(PB換算)": (
  //     <>
  //       終了種目までの結果と自己ベスト(PB)を組み合わせて最終得点を計算しています
  //     </>
  //   ),
  // };

  return (
    <Popover>
      <PopoverTrigger>
        <Button size="sm" fontWeight="light" variant="link">
          {event}とは？
          {/* <ExternalLinkIcon /> */}
        </Button>
      </PopoverTrigger>
      <PopoverContent maxWidth="max">
        <PopoverArrow />
        <PopoverCloseButton />
        <PopoverHeader>{event}とは</PopoverHeader>
        <PopoverBody>{explanation()}</PopoverBody>
      </PopoverContent>
    </Popover>
  );
};
