import { ExternalLinkIcon } from '@chakra-ui/icons';
import { Button } from '@chakra-ui/react';
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverArrow,
  PopoverCloseButton,
} from '@chakra-ui/react';
import { eventType } from '../../common/types';
import { DecaPointgaps } from '../../common/pointGaps';

export const approximatePointButton = (event: eventType) => {
  return (
    <Popover>
      <PopoverTrigger>
        <Button size="sm" fontWeight="light" variant="link">
          得点差の目安
          <ExternalLinkIcon />
        </Button>
      </PopoverTrigger>
      <PopoverContent maxWidth="100%">
        <PopoverArrow />
        <PopoverCloseButton />
        <PopoverHeader>おおよその得点差</PopoverHeader>
        <PopoverBody fontSize="md">{DecaPointgaps[event]}</PopoverBody>
      </PopoverContent>
    </Popover>
  );
};
