import React, { useState } from 'react';
import { Links } from '../page_calculator/components/links';

export const TopPage = () => {
  return (
    <>
      <Links />
    </>
  );
};
