export const eventNameDict = (EventName: string) => {
  if (['sb'].includes(EventName)) {
    return <>SB</>;
  }
  if (['pb'].includes(EventName)) {
    return <>PB</>;
  }
  if (['result'].includes(EventName)) {
    return <>結果</>;
  }
  if (['sbTotal'].includes(EventName)) {
    return (
      <>
        予想得点
        <br />
        SB換算
      </>
    );
  }
  if (['pbTotal'].includes(EventName)) {
    return (
      <>
        予想得点
        <br />
        PB換算
      </>
    );
  }
  if (['name'].includes(EventName)) {
    return <>名前</>;
  }
  if (['total'].includes(EventName)) {
    return <>総合得点</>;
  }
  if (['i00'].includes(EventName)) {
    return <>100m</>;
  }
  if (['lj'].includes(EventName)) {
    return <>走幅跳</>;
  }
  if (['sp'].includes(EventName)) {
    return <>砲丸投</>;
  }
  if (['hj'].includes(EventName)) {
    return <>走高跳</>;
  }
  if (['A00'].includes(EventName)) {
    return <>400m</>;
  }
  if (['ii0h'].includes(EventName)) {
    return <>110mH</>;
  }
  if (['dt'].includes(EventName)) {
    return <>円盤投</>;
  }
  if (['pv'].includes(EventName)) {
    return <>棒高跳</>;
  }
  if (['jt'].includes(EventName)) {
    return <>やり投</>;
  }
  if (['i500'].includes(EventName)) {
    return <>1500m</>;
  }
  if (['firstDay'].includes(EventName)) {
    return <>1日目</>;
  }
  if (['secondDay'].includes(EventName)) {
    return <>2日目</>;
  }
  if (['i00h'].includes(EventName)) {
    return <>100mH</>;
  }
  if (['z00'].includes(EventName)) {
    return <>200m</>;
  }
  if (['B00'].includes(EventName)) {
    return <>800m</>;
  }

  return <>{EventName}</>;
};

export const eventNameDictt = {
  // sb100: "100m",
  // sblj: "走幅跳",
  // sbsp: "砲丸投",
  // sbhl: "走高跳",
  // sb400: "400m",
  // sb110h: "110mH",
  // sbdt: "円盤投",
  // sbpv: "棒高跳",
  // sbjt: "やり投",
  // sb1500: "1500m",
  // i00h: "100mH",
  // hj: "走高跳",
  // sp: "砲丸投",
  // z00: "200m",
  // lj: "走幅跳",
  // jt: "やり投",
  // B00: "800m",
  // i00: "100m",
  // dt: "円盤投",
  // day1: "1日目",
  // day2: "2日目",
  // bo: "60m",
  // b0h: "60mH",
  // iooo: "1000m",
};
