import { eventNameType, indoorHeptathlonEventType } from '../../common/types';

const calc100score = (result: number) => {
  if (!result || Number(result) > 1800) {
    return 0;
  }
  return Math.trunc(25.4347 * (18 - Number(result) / 100) ** 1.81);
};
const calcLJscore = (result: number) => {
  if (!result || Number(result) < 221) {
    return 0;
  }
  return Math.trunc(0.14354 * (Number(result) - 220) ** 1.4);
};
const calcSPscore = (result: number) => {
  if (!result || Number(result) < 150) {
    return 0;
  }
  return Math.trunc(51.39 * (Number(result) / 100 - 1.5) ** 1.05);
};
const calcHJscore = (result: number) => {
  if (!result || Number(result) < 76) {
    return 0;
  }
  return Math.trunc(0.8465 * (Number(result) - 75) ** 1.42);
};
const calc400score = (result: number) => {
  if (!result || Number(result) > 8200) {
    return 0;
  }
  return Math.trunc(1.53775 * (82 - Number(result) / 100) ** 1.81);
};
const calc110score = (result: number) => {
  if (!result || Number(result) > 2850) {
    return 0;
  }
  return Math.trunc(5.74352 * (28.5 - Number(result) / 100) ** 1.92);
};
const calcDTscore = (result: number) => {
  if (!result || Number(result) < 500) {
    return 0;
  }
  return Math.trunc(12.91 * (Number(result) / 100 - 4) ** 1.1);
};
const calcPVscore = (result: number) => {
  if (!result || Number(result) < 100) {
    return 0;
  }
  return Math.trunc(0.2797 * (Number(result) - 100) ** 1.35);
};
const calcJTscore = (result: number) => {
  if (!result || Number(result) < 700) {
    return 0;
  }
  return Math.trunc(10.14 * (Number(result) / 100 - 7) ** 1.08);
};
const calc1500score = (result: number) => {
  if (!result || Number(result) > 80000) {
    return 0;
  }
  return Math.trunc(
    0.03768 *
      (480 -
        (Math.trunc(Number(result) / 10000) * 60 +
          (Number(result) % 10000) / 100)) **
        1.85
  );
};

const calc60score = (result: number) => {
  if (!result || Number(result) > 1150) {
    return 0;
  }
  return Math.trunc(58.015 * (11.5 - Number(result) / 100) ** 1.81);
};
const calc60hscore = (result: number) => {
  if (!result || Number(result) > 1550) {
    return 0;
  }
  return Math.trunc(20.5173 * (15.5 - Number(result) / 100) ** 1.92);
};
const calc1000score = (result: number) => {
  if (!result || Number(result) > 50550) {
    return 0;
  }
  return Math.trunc(
    0.08713 *
      (305.5 -
        (Math.trunc(Number(result) / 10000) * 60 +
          (Number(result) % 10000) / 100)) **
        1.85
  );
};

export const calcScoreByName = (eventName: eventNameType, result: number) => {
  if (['100m', 'ioo', 'i00'].includes(eventName)) {
    return calc100score(result);
  }
  if (['走幅跳', 'longJump', 'lj'].includes(eventName)) {
    return calcLJscore(result);
  }
  if (['砲丸投', 'shotPut', 'sp'].includes(eventName)) {
    return calcSPscore(result);
  }
  if (['走高跳', 'highJump', 'hj'].includes(eventName)) {
    return calcHJscore(result);
  }
  if (['400m', 'A00'].includes(eventName)) {
    return calc400score(result);
  }

  if (['110mH', 'ii0h'].includes(eventName)) {
    return calc110score(result);
  }
  if (['円盤投', 'discus', 'dt'].includes(eventName)) {
    return calcDTscore(result);
  }
  if (['棒高跳', 'pv'].includes(eventName)) {
    return calcPVscore(result);
  }
  if (['やり投', 'javThrow', 'jt'].includes(eventName)) {
    return calcJTscore(result);
  }
  if (['1500m', 'i500'].includes(eventName)) {
    return calc1500score(result);
  }
};

export const IndoorcalcScoreByName = (
  eventName: indoorHeptathlonEventType,
  result: number
) => {
  if (['60m', 'bo', 'b0'].includes(eventName)) {
    return calc60score(result);
  }
  if (['走幅跳', 'longJump', 'lj'].includes(eventName)) {
    return calcLJscore(result);
  }
  if (['砲丸投', 'shotPut', 'sp'].includes(eventName)) {
    return calcSPscore(result);
  }
  if (['走高跳', 'highJump', 'hj'].includes(eventName)) {
    return calcHJscore(result);
  }
  if (['b0mH', 'b0h'].includes(eventName)) {
    return calc60hscore(result);
  }
  if (['棒高跳', 'pv'].includes(eventName)) {
    return calcPVscore(result);
  }
  if (['i000m', 'i000'].includes(eventName)) {
    return calc1000score(result);
  }
};

const calc100record = (score: number) =>
  Math.trunc((18 - (Number(score) / 25.4347) ** (1 / 1.81)) * 100);
const calcLJrecord = (score: number) =>
  Math.trunc((Number(score) / 0.14354) ** (1 / 1.4) + 220);
const calcSPrecord = (score: number) =>
  Math.trunc(((Number(score) / 51.39) ** (1 / 1.05) + 1.5) * 100);
const calcHJrecord = (score: number) =>
  Math.trunc((Number(score) / 0.8465) ** (1 / 1.42) + 75);

const calc400record = (score: number) =>
  Math.trunc((82 - (Number(score) / 1.53775) ** (1 / 1.81)) * 100);

const calc110record = (score: number) =>
  Math.trunc((28.5 - (Number(score) / 5.74352) ** (1 / 1.92)) * 100);
const calcDTrecord = (score: number) =>
  Math.trunc(((Number(score) / 12.91) ** (1 / 1.1) + 4) * 100);
const calcPVrecord = (score: number) =>
  Math.trunc((Number(score) / 0.2797) ** (1 / 1.35) + 100);
const calcJTrecord = (score: number) =>
  Math.trunc(((Number(score) / 10.14) ** (1 / 1.08) + 7) * 100);
const calc1500record = (score: number) => {
  const byosu = 480 - (Number(score) / 0.03768) ** (1 / 1.85);
  const min = Math.trunc(byosu / 60);
  const secInt = Math.trunc((byosu - min * 60) * 100);
  return min * 10000 + secInt;
};
const calc60record = (score: number) =>
  Math.trunc((11.5 - (Number(score) / 58.015) ** (1 / 1.81)) * 100);
const calc60hrecord = (score: number) =>
  Math.trunc((15.5 - (Number(score) / 20.5173) ** (1 / 1.92)) * 100);
const calc1000record = (score: number) => {
  const byosu = 305.5 - (Number(score) / 0.08713) ** (1 / 1.85);
  const min = Math.trunc(byosu / 60);
  const secInt = Math.trunc((byosu - min * 60) * 100);
  return min * 10000 + secInt;
};

export const calcRecordByName = (eventName: eventNameType, result: number) => {
  if (['100m', 'ioo', 'i00'].includes(eventName)) {
    return calc100record(result);
  }
  if (['走幅跳', 'longJump', 'lj'].includes(eventName)) {
    return calcLJrecord(result);
  }
  if (['砲丸投', 'shotPut', 'sp'].includes(eventName)) {
    return calcSPrecord(result);
  }
  if (['走高跳', 'highJump', 'hj'].includes(eventName)) {
    return calcHJrecord(result);
  }
  if (['400m', 'A00'].includes(eventName)) {
    return calc400record(result);
  }
  if (['110mH', 'ii0h'].includes(eventName)) {
    return calc110record(result);
  }
  if (['円盤投', 'discus', 'dt'].includes(eventName)) {
    return calcDTrecord(result);
  }
  if (['棒高跳', 'pv'].includes(eventName)) {
    return calcPVrecord(result);
  }
  if (['やり投', 'javThrow', 'jt'].includes(eventName)) {
    return calcJTrecord(result);
  }
  if (['1500m', 'isoo', 'i500'].includes(eventName)) {
    return calc1500record(result);
  }
};

export const IndoorcalcRecordByName = (
  eventName: indoorHeptathlonEventType,
  result: number
) => {
  if (['60m', 'b0'].includes(eventName)) {
    return calc60record(result);
  }
  if (['走幅跳', 'lj'].includes(eventName)) {
    return calcLJrecord(result);
  }
  if (['砲丸投', 'sp'].includes(eventName)) {
    return calcSPrecord(result);
  }
  if (['走高跳', 'hj'].includes(eventName)) {
    return calcHJrecord(result);
  }
  if (['60mH', 'b0h'].includes(eventName)) {
    return calc60hrecord(result);
  }
  if (['棒高跳', 'pv'].includes(eventName)) {
    return calcPVrecord(result);
  }
  if (['1000m', 'i000'].includes(eventName)) {
    return calc1000record(result);
  }
};
