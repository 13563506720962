import React, { useState } from 'react';
import '../App.css';
import {
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Button,
  Box,
  Heading,
} from '@chakra-ui/react';
import { ResultHeptathlonTable } from './components/ResultHeptathlonTable';
import { heptathlonEventListType, heptathlonEventType } from '../common/types';

import { createHeptathlonForecastData } from './functions/createHeptathlonScoreData';
import { ResultHeptathlonEventTable } from './components/ResultHeptathlonEventTable';
import { Link } from 'react-router-dom';
import { ExternalLinkIcon } from '@chakra-ui/icons';
import {
  useHepCPBData,
  useHepCSBData,
  useHepPBData,
  useHepResultData,
  useHepSBData,
} from './request/requests';
import { ResultLink } from './components/ResultLink';
import { dateGame, titleGame } from './components/TitleGame';

export const HeptathlonResultTable = () => {
  const EventList: heptathlonEventListType = {
    i00h: '100mH',
    hj: '走高跳',
    sp: '砲丸投',
    z00: '200m',
    lj: '走幅跳',
    jt: 'やり投',
    B00: '800m',
  };

  //  papa.parseでスプレッドシートから記録を取得する
  const data = useHepResultData();
  const csbDataInitial = useHepCSBData();
  const cpbDataInitial = useHepCPBData();
  const sbData = useHepSBData();
  const pbData = useHepPBData();

  //  現在終了種目時点での予想記録を作成する
  const sbForecastData = data.map((d, i) =>
    createHeptathlonForecastData(
      d,
      !!csbDataInitial[i] ? csbDataInitial[i] : csbDataInitial[0]
    )
  );
  const pbForecastData = data.map((d, i) =>
    createHeptathlonForecastData(
      d,
      !!cpbDataInitial[i] ? cpbDataInitial[i] : cpbDataInitial[0]
    )
  );

  // タブスライダーの実装
  // const [tabIndex, setTabIndex] = useState(0);
  // const handleSliderChange = (event: any) => {
  //   setTabIndex(parseInt(event.target.value, 10));
  // };
  // const handleTabsChange = (index: number) => {
  //   setTabIndex(index);
  // };

  return (
    <>
      <Box m={'8px'}>
        <Heading as="h4" size="md">
          {dateGame}
          <br />
          {titleGame}
        </Heading>
        <ResultLink />
      </Box>
      <Tabs
        size="sm"
        variant="soft-rounded"
        colorScheme="green"
        // index={tabIndex}
        // onChange={handleTabsChange}
      >
        <TabList overflowX="auto" whiteSpace="nowrap">
          <Tab>総合順位</Tab>

          <Tab>
            予想順位
            <br />
            (SB換算)
          </Tab>
          <Tab>
            予想順位
            <br />
            (PB換算)
          </Tab>
          {Object.values(EventList).map((event, i) => {
            return <Tab key={i}>{event}</Tab>;
          })}
          <Tab>SB順位</Tab>
          <Tab>PB順位</Tab>
        </TabList>

        <TabPanels>
          <TabPanel>
            <ResultHeptathlonTable
              Data={data}
              rawData={data}
              title="総合順位"
              csbData={csbDataInitial}
              cpbData={cpbDataInitial}
              sbData={sbData}
              pbData={pbData}
            />
          </TabPanel>

          <TabPanel>
            <ResultHeptathlonTable
              Data={sbForecastData}
              rawData={data}
              title="予想順位(SB換算)"
              csbData={csbDataInitial}
              cpbData={cpbDataInitial}
              sbData={sbData}
              pbData={pbData}
            />
          </TabPanel>
          <TabPanel>
            <ResultHeptathlonTable
              Data={pbForecastData}
              rawData={data}
              title="予想順位(PB換算)"
              csbData={csbDataInitial}
              cpbData={cpbDataInitial}
              sbData={sbData}
              pbData={pbData}
            />
          </TabPanel>
          {Object.keys(EventList).map((event, i) => {
            const e = event as heptathlonEventType;
            return (
              <TabPanel key={i}>
                <ResultHeptathlonEventTable
                  Event={e}
                  Data={data}
                  csbData={csbDataInitial}
                  cpbData={cpbDataInitial}
                  sbData={sbData}
                  pbData={pbData}
                />
              </TabPanel>
            );
          })}
          <TabPanel>
            <ResultHeptathlonTable
              Data={csbDataInitial}
              rawData={data}
              title="SB順位"
              csbData={csbDataInitial}
              cpbData={cpbDataInitial}
              sbData={sbData}
              pbData={pbData}
            />
          </TabPanel>
          <TabPanel>
            <ResultHeptathlonTable
              Data={cpbDataInitial}
              rawData={data}
              title="PB順位"
              csbData={csbDataInitial}
              cpbData={cpbDataInitial}
              sbData={sbData}
              pbData={pbData}
            />
          </TabPanel>
        </TabPanels>
      </Tabs>

      {/* <Input
        type="range"
        min="0"
        max="14"
        value={tabIndex}
        onChange={handleSliderChange}
        position="fixed"
        bottom="0"
        zIndex="30"
        backgroundColor="white"
      /> */}
    </>
  );
};
