import {
  eventType,
  heptathlonEventType,
  octathlonEventType,
  tetrathlonWomanEventType,
  tetrathlonManEventType,
} from './types';

export const DecathlonEventList: eventType[] = [
  'i00',
  'lj',
  'sp',
  'hj',
  'A00',
  'ii0h',
  'dt',
  'pv',
  'jt',
  'i500',
];

export const OctathlonEventList: octathlonEventType[] = [
  'i00',
  'lj',
  'sp',
  'A00',
  'ii0h',
  'jt',
  'hj',
  'i500',
];

export const HeptathlonEventList: heptathlonEventType[] = [
  'i00h',
  'hj',
  'sp',
  'z00',
  'lj',
  'jt',
  'B00',
];

export const TetraManEventList: tetrathlonManEventType[] = [
  'ii0h',
  'sp',
  'hj',
  'A00',
];

export const TetraWomanEventList: tetrathlonWomanEventType[] = [
  'i00h',
  'hj',
  'sp',
  'z00',
];
