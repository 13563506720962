import axios from 'axios';
import { useEffect, useState } from 'react';
import { heptathlonSingleRecord, singleRecord } from '../../common/types';
import Papa from 'papaparse';
import { spreadSheetLink } from './spreadSheetLinks';
import {
  currentDataInitial,
  currentHeptathlonDataInitial,
} from '../components/constants/sbdatainitial';

const HOST = 'http://127.0.0.1:80';

export const getMyInfo = () => axios.get(HOST + '/myinfo');

export const getShimizuInfo = () => axios.get(HOST + '/shimizu');

export const useResultData = () => useGetData(spreadSheetLink['decaResult']);
export const useProfileData = () => useGetData(spreadSheetLink['profile']);
export const useCSBData = () => useGetData(spreadSheetLink['CSB']);
export const useCPBData = () => useGetData(spreadSheetLink['CPB']);
export const useSBData = () => useGetData(spreadSheetLink['SB']);
export const usePBData = () => useGetData(spreadSheetLink['PB']);
export const useU20ResultData = () =>
  useGetData(spreadSheetLink['U20decaResult']);
export const useU20CSBData = () => useGetData(spreadSheetLink['U20CSB']);
export const useU20CPBData = () => useGetData(spreadSheetLink['U20CPB']);
export const useU20SBData = () => useGetData(spreadSheetLink['U20SB']);
export const useU20PBData = () => useGetData(spreadSheetLink['U20PB']);

export const useHepResultData = () =>
  useHepGetData(spreadSheetLink['hepResult']);
export const useHepCSBData = () => useHepGetData(spreadSheetLink['hepCSB']);
export const useHepCPBData = () => useHepGetData(spreadSheetLink['hepCPB']);
export const useHepSBData = () => useHepGetData(spreadSheetLink['hepSB']);
export const useHepPBData = () => useHepGetData(spreadSheetLink['hepPB']);
export const useU20HepResultData = () =>
  useHepGetData(spreadSheetLink['U20hepResult']);
export const useU20HepCSBData = () =>
  useHepGetData(spreadSheetLink['U20hepCSB']);
export const useU20HepCPBData = () =>
  useHepGetData(spreadSheetLink['U20hepCPB']);
export const useU20HepSBData = () => useHepGetData(spreadSheetLink['U20hepSB']);
export const useU20HepPBData = () => useHepGetData(spreadSheetLink['U20hepPB']);

const useGetData = (link: string) => {
  const [data, setData] = useState<singleRecord[]>(currentDataInitial);
  useEffect(() => {
    Papa.parse(link, {
      download: true,
      header: true,
      complete: (results: any) => {
        setData(results.data);
      },
    });
  }, [link]);
  return data;
};

const useHepGetData = (link: string) => {
  const [data, setData] = useState<heptathlonSingleRecord[]>(
    currentHeptathlonDataInitial
  );
  useEffect(() => {
    Papa.parse(link, {
      download: true,
      header: true,
      complete: (results: any) => {
        setData(results.data);
      },
    });
  }, [link]);
  return data;
};
