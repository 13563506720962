import { Text } from '@chakra-ui/react';

const calc100score = (result: number) => {
  if (!result || result > 1800) {
    return 0;
  }
  return Math.trunc(25.4347 * (18 - Number(result) / 100) ** 1.81);
};
const calcLJscore = (result: number) => {
  if (!result || result < 221) {
    return 0;
  }
  return Math.trunc(0.14354 * (Number(result) - 220) ** 1.4);
};
const calcSPscore = (result: number) => {
  if (!result || Number(result) < 150) {
    return 0;
  }
  return Math.trunc(51.39 * (Number(result) / 100 - 1.5) ** 1.05);
};
const calcHJscore = (result: number) => {
  if (!result || result < 76) {
    return 0;
  }
  return Math.trunc(0.8465 * (Number(result) - 75) ** 1.42);
};
const calc400score = (result: number) => {
  if (!result || result > 8200) {
    return 0;
  }
  return Math.trunc(1.53775 * (82 - Number(result) / 100) ** 1.81);
};
const calc110score = (result: number) => {
  if (!result || result > 2850) {
    return 0;
  }
  return Math.trunc(5.74352 * (28.5 - Number(result) / 100) ** 1.92);
};
const calcDTscore = (result: number) => {
  if (!result || result < 500) {
    return 0;
  }
  return Math.trunc(12.91 * (Number(result) / 100 - 4) ** 1.1);
};
const calcPVscore = (result: number) => {
  if (!result || result < 100) {
    return 0;
  }
  return Math.trunc(0.2797 * (Number(result) - 100) ** 1.35);
};
const calcJTscore = (result: number) => {
  if (!result || result < 700) {
    return 0;
  }
  return Math.trunc(10.14 * (Number(result) / 100 - 7) ** 1.08);
};
const calc1500score = (result: number) => {
  if (!result || result > 80000) {
    return 0;
  }
  return Math.trunc(
    0.03768 *
      (480 -
        (Math.trunc(Number(result) / 10000) * 60 +
          (Number(result) % 10000) / 100)) **
        1.85
  );
};

export const calcPointToJSX = (EventName: string, result: string) => {
  const kiroku = Number(result);
  // 名前だけ処理が違う。
  if (['name'].includes(EventName)) {
    return (
      <Text fontSize={'7pt'} color={'GrayText'}>
        {String(result).split('-')[1] || ''}
      </Text>
    );
  }

  //この下は同じような処理内容
  if (['i00'].includes(EventName)) {
    return <>({calc100score(kiroku)})</>;
  }
  if (['lj'].includes(EventName)) {
    return <>({calcLJscore(kiroku)})</>;
  }
  if (['sp'].includes(EventName)) {
    return <>({calcSPscore(kiroku)})</>;
  }
  if (['hj'].includes(EventName)) {
    return <>({calcHJscore(kiroku)})</>;
  }
  if (['A00'].includes(EventName)) {
    return <>({calc400score(kiroku)})</>;
  }
  if (['ii0h'].includes(EventName)) {
    return <>({calc110score(kiroku)})</>;
  }
  if (['dt'].includes(EventName)) {
    return <>({calcDTscore(kiroku)})</>;
  }
  if (['pv'].includes(EventName)) {
    return <>({calcPVscore(kiroku)})</>;
  }
  if (['jt'].includes(EventName)) {
    return <>({calcJTscore(kiroku)})</>;
  }
  if (['i500'].includes(EventName)) {
    return <>({calc1500score(kiroku)})</>;
  }
};

export const calcPointToJSXForSort = (
  title: string,
  EventName: string,
  result: string
) => {
  const kiroku = Number(result);
  if (['total', 'sbTotal', 'pbTotal'].includes(title)) {
    return;
  }
  if (['i00'].includes(EventName)) {
    return <>({calc100score(kiroku)})</>;
  }
  if (['lj'].includes(EventName)) {
    return <>({calcLJscore(kiroku)})</>;
  }
  if (['sp'].includes(EventName)) {
    return <>({calcSPscore(kiroku)})</>;
  }
  if (['hj'].includes(EventName)) {
    return <>({calcHJscore(kiroku)})</>;
  }
  if (['A00'].includes(EventName)) {
    return <>({calc400score(kiroku)})</>;
  }
  if (['ii0h'].includes(EventName)) {
    return <>({calc110score(kiroku)})</>;
  }
  if (['dt'].includes(EventName)) {
    return <>({calcDTscore(kiroku)})</>;
  }
  if (['pv'].includes(EventName)) {
    return <>({calcPVscore(kiroku)})</>;
  }
  if (['jt'].includes(EventName)) {
    return <>({calcJTscore(kiroku)})</>;
  }
  if (['i500'].includes(EventName)) {
    return <>({calc1500score(kiroku)})</>;
  }
};

export const calcPoint = (EventName: string, result: string) => {
  const kiroku = Number(result);

  if (['total', 'firstDay', 'secondDay'].includes(EventName)) {
    return kiroku;
  }
  if (['i00'].includes(EventName)) {
    return calc100score(kiroku);
  }
  if (['lj'].includes(EventName)) {
    return calcLJscore(kiroku);
  }
  if (['sp'].includes(EventName)) {
    return calcSPscore(kiroku);
  }
  if (['hj'].includes(EventName)) {
    return calcHJscore(kiroku);
  }
  if (['A00'].includes(EventName)) {
    return calc400score(kiroku);
  }
  if (['ii0h'].includes(EventName)) {
    return calc110score(kiroku);
  }
  if (['dt'].includes(EventName)) {
    return calcDTscore(kiroku);
  }
  if (['pv'].includes(EventName)) {
    return calcPVscore(kiroku);
  }
  if (['jt'].includes(EventName)) {
    return calcJTscore(kiroku);
  }
  if (['i500'].includes(EventName)) {
    return calc1500score(kiroku);
  }

  return 0;
};

//
export const calcPointForSort = (
  title: string,
  EventName: string,
  result: string
) => {
  const kiroku = Number(result);

  if (['name', 'total', 'sbTotal', 'pbTotal'].includes(title)) {
    return kiroku;
  }
  if (['i00'].includes(EventName)) {
    return calc100score(kiroku);
  }
  if (['lj'].includes(EventName)) {
    return calcLJscore(kiroku);
  }
  if (['sp'].includes(EventName)) {
    return calcSPscore(kiroku);
  }
  if (['hj'].includes(EventName)) {
    return calcHJscore(kiroku);
  }
  if (['A00'].includes(EventName)) {
    return calc400score(kiroku);
  }
  if (['ii0h'].includes(EventName)) {
    return calc110score(kiroku);
  }
  if (['dt'].includes(EventName)) {
    return calcDTscore(kiroku);
  }
  if (['pv'].includes(EventName)) {
    return calcPVscore(kiroku);
  }
  if (['jt'].includes(EventName)) {
    return calcJTscore(kiroku);
  }
  if (['i500'].includes(EventName)) {
    return calc1500score(kiroku);
  }

  return 0;
};
