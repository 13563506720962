import twitter from '../../static/twitter.png';
import instagram from '../../static/instagram.png';
import tiktok from '../../static/tiktok.png';
import moriguchi from '../../static/ProfileMan/moriguchi.png';
import ushiroH from '../../static/ProfileMan/ushiroH.png';
import kazuya from '../../static/ProfileMan/kazuya.png';
import taue from '../../static/ProfileMan/taue.png';
import okuda from '../../static/ProfileMan/okuda.png';
import maegawa from '../../static/ProfileMan/maegawa2.png';
import kitagawa from '../../static/ProfileMan/kitagawa.png';
import oka from '../../static/ProfileMan/oka.png';

// プロフィールデータの型定義
type Profile = {
  name: string;
  imageSrc: string;
  description: string;
  socialMedia: Array<{
    platform: string;
    icon: string;
    url: string;
    handle: string;
  }>;
};

// プロフィールリストの定義
export const profiles: Profile[] = [
  {
    name: '片山 和也-烏城塗装工業',
    imageSrc: kazuya,
    description:
      '岡山県産🍑 KING OF ATHLETE👑\nきび団子🍡はいかが？\n２日目から追い上げまっせ💪\n片山和也にご注目！',
    socialMedia: [
      {
        platform: 'Twitter',
        icon: twitter,
        url: 'https://twitter.com/iam_kz_8',
        handle: '@iam_kz_8',
      },
      {
        platform: 'TikTok',
        icon: tiktok,
        url: 'https://www.tiktok.com/@iam_kz8?lang=ja-JP',
        handle: '@iam_kz_8',
      },
      {
        platform: 'Instagram',
        icon: instagram,
        url: 'https://www.instagram.com/iam_kz_8/',
        handle: '@iam_kz_8',
      },
    ],
  },
  {
    name: '森口 諒也-オリコ',
    imageSrc: moriguchi,
    description:
      '森口でっせ！\nこんなに大きく成長しました！\n超ひらパー兄さん目指して頑張ります！',
    socialMedia: [
      {
        platform: 'Twitter',
        icon: twitter,
        url: 'https://twitter.com/M_my_name_R',
        handle: '@M_my_name_R',
      },
      {
        platform: 'Instagram',
        icon: instagram,
        url: 'https://www.instagram.com/gigantodesse/',
        handle: '@gigantodesse',
      },
    ],
  },
  {
    name: '北川 嵩人-国士舘大',
    imageSrc: kitagawa,
    description: '筋トレにハマったのび太くん！？！？\nメガネが本体です👓',
    socialMedia: [
      {
        platform: 'Twitter',
        icon: twitter,
        url: 'https://twitter.com/kita__9781',
        handle: '@kita__9781',
      },
      {
        platform: 'Instagram',
        icon: instagram,
        url: 'https://www.instagram.com/kita__9781/',
        handle: '@kita__9781',
      },
    ],
  },
  {
    name: '右代 啓欣-エントリー',
    imageSrc: ushiroH,
    description: 'オーストラリアで武者修行中。\n可愛い笑顔にご注目！',
    socialMedia: [
      {
        platform: 'Instagram',
        icon: instagram,
        url: 'https://www.instagram.com/hiro_dietro/',
        handle: '@hiro_dietro',
      },
    ],
  },

  {
    name: '田上 駿-陸上物語',
    imageSrc: taue,
    description: 'なぜか年中日焼けしてますが💁🏽\n勝負強さに定評あります🫶',
    socialMedia: [
      {
        platform: 'Twitter',
        icon: twitter,
        url: 'https://twitter.com/ShunShun_deca',
        handle: '@ShunShun_deca',
      },
      {
        platform: 'Instagram',
        icon: instagram,
        url: 'https://www.instagram.com/shun_deca530/',
        handle: '@shun_deca530',
      },
    ],
  },

  {
    name: '奥田 啓祐-ウィザス',
    imageSrc: okuda,
    description: 'いつも写真はこのポーズ✌️\n奥田です✌️',
    socialMedia: [
      {
        platform: 'Twitter',
        icon: twitter,
        url: 'https://twitter.com/Field1023',
        handle: '@Field1023',
      },
      {
        platform: 'Instagram',
        icon: instagram,
        url: 'https://www.instagram.com/okd1023/',
        handle: '@okd1023',
      },
    ],
  },

  {
    name: '前川 斉幸-愛知陸協',
    imageSrc: maegawa,
    description: 'ウルトラマンに少しでも近づくぞ！\n前川行きまーす！',
    socialMedia: [
      {
        platform: 'Twitter',
        icon: twitter,
        url: 'https://twitter.com/makkitf85deca',
        handle: '@makkitf85deca',
      },
      {
        platform: 'Instagram',
        icon: instagram,
        url: 'https://www.instagram.com/makkitf85/',
        handle: '@makkitf85',
      },
    ],
  },

  {
    name: '岡 泰我-国士舘大',
    imageSrc: oka,
    description: 'すき家のキングは余裕ですよね？\nキング目指して頑張ります！',
    socialMedia: [],
  },
  // 他のプロフィールを追加可能
];
