export const eventNameDict = {
  i00: "100m",
  lj: "走幅跳",
  sp: "砲丸投",
  hj: "走高跳",
  A00: "400m",
  ii0h: "110mH",
  dt: "円盤投",
  pv: "棒高跳",
  jt: "やり投",
  i500: "1500m",
  i00h: "100mH",
  z00: "200m",
  B00: "800m",
  day1: "1日目",
  day2: "2日目",
  b0: "60m",
  b0h: "60mH",
  i000: "1000m",
};

// export const DecathlonEventList = [
//   "ioo",
//   "longJump",
//   "shotPut",
//   "highJump",
//   "A00",
//   "ii0h",
//   "discus",
//   "pv",
//   "javThrow",
//   "isoo",
// ];

// export const OctathlonEventList = [
//   "ioo",
//   "longJump",
//   "shotPut",
//   "A00",
//   "ii0h",
//   "javThrow",
//   "highJump",
//   "isoo",
// ];

// export const HeptathlonEventList = [
//   "i00h",
//   "hj",
//   "sp",
//   "z00",
//   "lj",
//   "jt",
//   "B00",
// ];
