import { eventType, heptathlonEventType } from '../../common/types';

export type decathlonkeys = eventType | 'day1' | 'day2';
export type heptathlonkeys = heptathlonEventType | 'day1' | 'day2';

export type recordType = {
  [key in decathlonkeys]?: { name: string; record: string };
};
export type heptathlonRecordType = {
  [key in heptathlonkeys]?: { name: string; record: string };
};

export type manRecordListType = { title: string; records: recordType };
export type womanRecordListType = {
  title: string;
  records: heptathlonRecordType;
};

export const manRecordList: manRecordListType[] = [
  {
    title: '日本記録は何点？',
    records: {
      i00: { name: '右代啓祐', record: '1124' },
      lj: { name: '右代啓祐', record: '715' },
      sp: { name: '右代啓祐', record: '1519' },
      hj: { name: '右代啓祐', record: '203' },
      A00: { name: '右代啓祐', record: '4966' },
      ii0h: { name: '右代啓祐', record: '1490' },
      dt: { name: '右代啓祐', record: '5017' },
      pv: { name: '右代啓祐', record: '480' },
      jt: { name: '右代啓祐', record: '6911' },
      i500: { name: '右代啓祐', record: '43262' },
    },
  },

  {
    title: '世界記録は何点？',
    records: {
      i00: { name: 'Kevin MAYER', record: '1055' },
      lj: { name: 'Kevin MAYER', record: '780' },
      sp: { name: 'Kevin MAYER', record: '1600' },
      hj: { name: 'Kevin MAYER', record: '205' },
      A00: { name: 'Kevin MAYER', record: '4842' },
      ii0h: { name: 'Kevin MAYER', record: '1375' },
      dt: { name: 'Kevin MAYER', record: '5054' },
      pv: { name: 'Kevin MAYER', record: '545' },
      jt: { name: 'Kevin MAYER', record: '7190' },
      i500: { name: 'Kevin MAYER', record: '43611' },
    },
  },

  {
    title: '全部日本記録だったら何点？',
    records: {
      i00: { name: '山縣亮太', record: '995' },
      lj: { name: '城山正太郎', record: '840' },
      sp: { name: '中村太地', record: '1885' },
      hj: { name: '醍醐直幸', record: '233' },
      A00: { name: '高野進', record: '4478' },
      ii0h: { name: '泉谷駿介', record: '1306' },
      dt: { name: '堤雄司', record: '6259' },
      pv: { name: '澤野大地', record: '583' },
      jt: { name: '溝口和洋', record: '8760' },
      i500: { name: '河村一輝', record: '33542' },
    },
  },

  {
    title: '全部世界記録だったら何点？',
    records: {
      i00: { name: 'Usain BOLT', record: '958' },
      lj: { name: 'Mike POWELL', record: '895' },
      sp: { name: 'Ryan CROUSER', record: '2337' },
      hj: { name: 'Javier SOTOMAYOR', record: '245' },
      A00: { name: 'Wayde VAN NIEKERK', record: '4303' },
      ii0h: { name: 'Aries MERRITT', record: '1280' },
      dt: { name: 'Jürgen SCHULT', record: '7408' },
      pv: { name: 'Armand DUPLANTIS', record: '621' },
      jt: { name: 'Jan ŽELEZNÝ', record: '9848' },
      i500: { name: 'Hicham EL GUERROUJ', record: '32600' },
    },
  },

  {
    title: '種目別日本記録は何点？',
    records: {
      i00: { name: '音部拓仁', record: '1052' },
      lj: { name: '中村明彦', record: '765' },
      sp: { name: '右代啓祐', record: '1565' },
      hj: { name: '氏野修次', record: '216' },
      A00: { name: '中村明彦', record: '4717' },
      ii0h: { name: '能登谷雄太', record: '1397' },
      dt: { name: '右代啓祐', record: '5023' },
      pv: { name: '横山学', record: '530' },
      jt: { name: '右代啓祐', record: '7382' },
      i500: { name: '中村明彦', record: '40824' },
      day1: { name: '中村明彦', record: '4278' },
      day2: { name: '右代啓祐', record: '4188' },
    },
  },

  {
    title: '種目別世界記録は何点？',
    records: {
      i00: { name: 'Damian WARNER', record: '1012' },
      lj: { name: 'Simon EHAMMER', record: '845' },
      sp: { name: 'Edy HUBACHER', record: '1917' },
      hj: { name: 'Derek DROUIN', record: '228' },
      A00: { name: 'Ashton EATON', record: '4500' },
      ii0h: { name: 'Damian WARNER', record: '1336' },
      dt: { name: 'Bryan CLAY', record: '5587' },
      pv: { name: 'Tim LOBINGER', record: '576' },
      jt: { name: 'Peter BLANK', record: '7980' },
      i500: { name: 'Robert BAKER', record: '35870' },
      day1: { name: "Dan O'BRIEN", record: '4747' },
      day2: { name: 'Kevin MAYER', record: '4563' },
    },
  },
];

export const womanRecordList: womanRecordListType[] = [
  {
    title: '日本記録は何点？',
    records: {
      i00h: { name: '山﨑有紀', record: '1400' },
      hj: { name: '山﨑有紀', record: '165' },
      sp: { name: '山﨑有紀', record: '1239' },
      z00: { name: '山﨑有紀', record: '2463' },
      lj: { name: '山﨑有紀', record: '601' },
      jt: { name: '山﨑有紀', record: '4862' },
      B00: { name: '山﨑有紀', record: '21395' },
    },
  },

  {
    title: '世界記録は何点？',
    records: {
      i00h: { name: 'Jackie JOYNER-KERSEE', record: '1269' },
      hj: { name: 'Jackie JOYNER-KERSEE', record: '186' },
      sp: { name: 'Jackie JOYNER-KERSEE', record: '1580' },
      z00: { name: 'Jackie JOYNER-KERSEE', record: '2256' },
      lj: { name: 'Jackie JOYNER-KERSEE', record: '727' },
      jt: { name: 'Jackie JOYNER-KERSEE', record: '4566' },
      B00: { name: 'Jackie JOYNER-KERSEE', record: '20851' },
    },
  },

  {
    title: '全部日本記録だったら何点？',
    records: {
      i00h: { name: '青木益未', record: '1286' },
      hj: { name: '今井美希', record: '196' },
      sp: { name: '森千夏', record: '1822' },
      z00: { name: '福島千里', record: '2288' },
      lj: { name: '池田久美子', record: '686' },
      jt: { name: '北口榛花', record: '6600' },
      B00: { name: '杉森美保', record: '20045' },
    },
  },

  {
    title: '全部世界記録だったら何点？',
    records: {
      i00h: { name: 'Tobi AMUSAN', record: '1212' },
      hj: { name: 'Stefka KOSTADINOVA', record: '209' },
      sp: { name: 'Natalya LISOVSKAYA', record: '2263' },
      z00: { name: 'Florence GRIFFITH-JOYNER', record: '2134' },
      lj: { name: 'Galina CHISTYAKOVA', record: '752' },
      jt: { name: 'Barbora ŠPOTÁKOVÁ', record: '7228' },
      B00: { name: 'Jarmila KRATOCHVÍLOVÁ', record: '15328' },
    },
  },

  {
    title: '種目別日本記録は何点？',
    records: {
      i00h: { name: 'ヘンプピル惠', record: '1343' },
      hj: { name: '屋ケ田直美', record: '181' },
      sp: { name: '平戸案紀子', record: '1476' },
      z00: { name: '笠原瑞世', record: '2435' },
      lj: { name: '中田有紀', record: '630' },
      jt: { name: '森友佳', record: '5745' },
      B00: { name: '宇都宮絵莉', record: '20980' },
      day1: { name: '中田有紀', record: '2463' },
      day2: { name: '中田有紀', record: '2534' },
    },
  },

  {
    title: '種目別世界記録は何点？',
    records: {
      i00h: { name: 'Jessica ENISS-Hill', record: '1254' },
      hj: { name: 'Nafissatou THIAM', record: '202' },
      sp: { name: 'Austra SKUJYTE', record: '1731' },
      z00: { name: 'Jackie JOYNER-KERSEE', record: '2230' },
      lj: { name: 'Jackie JOYNER-KERSEE', record: '727' },
      jt: { name: 'Barbora SPOTAKOVA', record: '6090' },
      B00: { name: 'Nadine DEBOIS', record: '20184' },
      day1: { name: 'Jackie JOYNER-KERSEE', record: '4376' },
      day2: { name: 'Jackie JOYNER-KERSEE', record: '3027' },
    },
  },
];
