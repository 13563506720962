import {
  heptathlonEventType,
  indoorPentathlonEventType,
} from "../../common/types";

const calc60hpoint = (result: number) => {
  if (!result || result > 2670) {
    return 0;
  }
  return Math.trunc(20.0479 * (17 - Number(result) / 100) ** 1.835);
};
const calc60hrecord = (result: number) => {
  if (!result || result === 0) {
    return 0;
  }
  return Math.trunc((17 - (result / 20.0479) ** (1 / 1.835)) * 100);
};
const calc100hpoint = (result: number) => {
  if (!result || result > 2670) {
    return 0;
  }
  return Math.trunc(9.23076 * (26.7 - Number(result) / 100) ** 1.835);
};
const calc100hrecord = (result: number) => {
  if (!result || result === 0) {
    return 0;
  }
  return Math.trunc((26.7 - (result / 9.23076) ** (1 / 1.835)) * 100);
};
const calcHJpoint = (result: number) => {
  if (!result || result < 75) {
    return 0;
  }
  return Math.trunc(1.84523 * (Number(result) - 75) ** 1.348);
};

const calcHJrecord = (result: number) => {
  if (!result || result === 0) {
    return 0;
  }
  return Math.trunc(75 + (Number(result) / 1.84523) ** (1 / 1.348));
};

const calcSPpoint = (result: number) => {
  if (!result || result < 150) {
    return 0;
  }
  return Math.trunc(56.0211 * (Number(result) / 100 - 1.5) ** 1.05);
};
const calcSPrecord = (result: number) => {
  if (!result || result === 0) {
    return 0;
  }
  return Math.trunc((1.5 + (Number(result) / 56.0211) ** (1 / 1.05)) * 100);
};
const calc200point = (result: number) => {
  if (!result || result > 4250) {
    return 0;
  }
  return Math.trunc(4.99087 * (42.5 - Number(result) / 100) ** 1.81);
};
const calc200record = (result: number) => {
  if (!result || result === 0) {
    return 0;
  }
  return Math.trunc((42.5 - (Number(result) / 4.99087) ** (1 / 1.81)) * 100);
};
const calcLJpoint = (result: number) => {
  if (!result || result < 210) {
    return 0;
  }
  return Math.trunc(0.188807 * (Number(result) - 210) ** 1.41);
};
const calcLJrecord = (result: number) => {
  if (!result || result === 0) {
    return 0;
  }
  return Math.trunc(210 + (Number(result) / 0.188807) ** (1 / 1.41));
};
const calcJTpoint = (result: number) => {
  if (!result || result < 380) {
    return 0;
  }
  return Math.trunc(15.9803 * (Number(result) / 100 - 3.8) ** 1.04);
};
const calcJTrecord = (result: number) => {
  if (!result || result === 0) {
    return 0;
  }
  return Math.trunc((3.8 + (Number(result) / 15.9803) ** (1 / 1.04)) * 100);
};
const calc800point = (result: number) => {
  if (!result || result > 41400) {
    return 0;
  }
  return Math.trunc(
    0.11193 *
      (254 -
        (Math.trunc(Number(result) / 10000) * 60 +
          (Number(result) % 10000) / 100)) **
        1.88
  );
};
const calc800record = (result: number) => {
  if (!result || result === 0) {
    return 0;
  }
  const byosu = 254 - (result / 0.11193) ** (1 / 1.88);
  const min = Math.trunc(byosu / 60);
  const sec = Math.trunc((byosu - min * 60) * 100);
  return min * 10000 + sec;
};

export const calcPoint = (
  HepEventName: heptathlonEventType,
  result: number
) => {
  if (["100MH", "i00h", "100mH"].includes(HepEventName)) {
    return calc100hpoint(result);
  }
  if (["HighJump", "hj", "走高跳"].includes(HepEventName)) {
    return calcHJpoint(result);
  }
  if (["ShotPut", "sp", "砲丸投"].includes(HepEventName)) {
    return calcSPpoint(result);
  }
  if (["200M", "z00", "200m"].includes(HepEventName)) {
    return calc200point(result);
  }
  if (["LongJump", "lj", "走幅跳"].includes(HepEventName)) {
    return calcLJpoint(result);
  }
  if (["JavelinThrow", "jt", "やり投"].includes(HepEventName)) {
    return calcJTpoint(result);
  }
  if (["800M", "B00", "800m"].includes(HepEventName)) {
    return calc800point(result);
  }
};

export const calcRecord = (
  HepEventName: heptathlonEventType,
  result: number
) => {
  if (["100MH", "i00h"].includes(HepEventName)) {
    return calc100hrecord(result);
  }
  if (["HighJump", "hj"].includes(HepEventName)) {
    return calcHJrecord(result);
  }
  if (["ShotPut", "sp"].includes(HepEventName)) {
    return calcSPrecord(result);
  }
  if (["200M", "z00"].includes(HepEventName)) {
    return calc200record(result);
  }
  if (["LongJump", "lj"].includes(HepEventName)) {
    return calcLJrecord(result);
  }
  if (["JavelinThrow", "jt"].includes(HepEventName)) {
    return calcJTrecord(result);
  }
  if (["800M", "B00"].includes(HepEventName)) {
    return calc800record(result);
  }
};
export const IndoorcalcPoint = (
  PentaEventName: indoorPentathlonEventType,
  result: number
) => {
  if (["b0MH", "b0h", "b0mH"].includes(PentaEventName)) {
    return calc60hpoint(result);
  }
  if (["HighJump", "hj", "走高跳"].includes(PentaEventName)) {
    return calcHJpoint(result);
  }
  if (["ShotPut", "sp", "砲丸投"].includes(PentaEventName)) {
    return calcSPpoint(result);
  }
  if (["LongJump", "lj", "走幅跳"].includes(PentaEventName)) {
    return calcLJpoint(result);
  }
  if (["800M", "B00", "800m"].includes(PentaEventName)) {
    return calc800point(result);
  }
};

export const IndoorcalcRecord = (
  PentaEventName: indoorPentathlonEventType,
  result: number
) => {
  if (["b0MH", "b0h"].includes(PentaEventName)) {
    return calc60hrecord(result);
  }
  if (["HighJump", "hj"].includes(PentaEventName)) {
    return calcHJrecord(result);
  }
  if (["ShotPut", "sp"].includes(PentaEventName)) {
    return calcSPrecord(result);
  }
  if (["LongJump", "lj"].includes(PentaEventName)) {
    return calcLJrecord(result);
  }
  if (["800M", "B00"].includes(PentaEventName)) {
    return calc800record(result);
  }
};
