import {
  getDataListType,
  heptathlonSingleRecord,
  shownDataType,
  singleRecord,
} from '../../../common/types';

export const dataInitial: getDataListType[] = [
  {
    name: 'ロード中',
    i00: '',
    lj: '',
    sp: '',
    hj: '',
    A00: '',
    ii0h: '',
    dt: '',
    pv: '',
    jt: '',
    i500: '',
    sbi00: '',
    sblj: '',
    sbsp: '',
    sbhj: '',
    sbA00: '',
    sbii0h: '',
    sbdt: '',
    sbpv: '',
    sbjt: '',
    sbi500: '',
    pbi00: '',
    pblj: '',
    pbsp: '',
    pbhj: '',
    pbA00: '',
    pbii0h: '',
    pbdt: '',
    pbpv: '',
    pbjt: '',
    pbi500: '',
  },
];

export const currentDataInitial: singleRecord[] = [
  {
    name: 'ロード中--',
    i00: '',
    lj: '',
    sp: '',
    hj: '',
    A00: '',
    ii0h: '',
    dt: '',
    pv: '',
    jt: '',
    i500: '',
  },
];
export const showDataInitial: shownDataType = {
  name: 'ロード中--',
  i00: '',
  lj: '',
  sp: '',
  hj: '',
  A00: '',
  ii0h: '',
  dt: '',
  pv: '',
  jt: '',
  i500: '',
  total: '',
  firstDay: '',
  secondDay: '',
};
// {
//   name: "中村明彦",
//   i00: "",
//   lj: "",
//   sp: "",
//   hj: "",
//   A00: "",
//   ii0h: "",
//   dt: "",
//   pv: "",
//   jt: "",
//   i500: "",
// },
// {
//   name: "右代啓祐",
//   i00: "",
//   lj: "",
//   sp: "",
//   hj: "",
//   A00: "",
//   ii0h: "",
//   dt: "",
//   pv: "",
//   jt: "",
//   i500: "",
// },
// {
//   name: "奥田",
//   i00: "",
//   lj: "",
//   sp: "",
//   hj: "",
//   A00: "",
//   ii0h: "",
//   dt: "",
//   pv: "",
//   jt: "",
//   i500: "",
// },
// {
//   name: "片山",
//   i00: "",
//   lj: "",
//   sp: "",
//   hj: "",
//   A00: "",
//   ii0h: "",
//   dt: "",
//   pv: "",
//   jt: "",
//   i500: "",
// },
// {
//   name: "田上",
//   i00: "",
//   lj: "",
//   sp: "",
//   hj: "",
//   A00: "",
//   ii0h: "",
//   dt: "",
//   pv: "",
//   jt: "",
//   i500: "",
// },

export const sbDataInitial: singleRecord[] = [
  {
    name: 'ロード中--',
    i00: '',
    lj: '',
    sp: '',
    hj: '',
    A00: '',
    ii0h: '',
    dt: '',
    pv: '',
    jt: '',
    i500: '',
  },
  // {
  //   name: "中村明彦",
  //   i00: "1077",
  //   lj: "736",
  //   sp: "1284",
  //   hj: "194",
  //   A00: "4938",
  //   ii0h: "1434",
  //   dt: "3481",
  //   pv: "480",
  //   jt: "5618",
  //   i500: "42903",
  // },
  // {
  //   name: "右代啓祐",
  //   i00: "1146",
  //   lj: "694",
  //   sp: "1449",
  //   hj: "195",
  //   A00: "5203",
  //   ii0h: "1506",
  //   dt: "4843",
  //   pv: "480",
  //   jt: "6245",
  //   i500: "43967",
  // },
  // {
  //   name: "奥田",
  //   i00: "1070",
  //   lj: "735",
  //   sp: "1267",
  //   hj: "194",
  //   A00: "4759",
  //   ii0h: "1454",
  //   dt: "3813",
  //   pv: "440",
  //   jt: "5499",
  //   i500: "43870",
  // },
  // {
  //   name: "片山",
  //   i00: "1093",
  //   lj: "710",
  //   sp: "1354",
  //   hj: "185",
  //   A00: "5094",
  //   ii0h: "1512",
  //   dt: "4267",
  //   pv: "460",
  //   jt: "6123",
  //   i500: "44789",
  // },
  // {
  //   name: "田上",
  //   i00: "1098",
  //   lj: "728",
  //   sp: "1254",
  //   hj: "186",
  //   A00: "4942",
  //   ii0h: "1400",
  //   dt: "3831",
  //   pv: "450",
  //   jt: "5907",
  //   i500: "42628",
  // },
];

export const pbDataInitial: singleRecord[] = [
  {
    name: 'ロード中--',
    i00: '',
    lj: '',
    sp: '',
    hj: '',
    A00: '',
    ii0h: '',
    dt: '',
    pv: '',
    jt: '',
    i500: '',
  },
  // {
  //   name: "中村明彦",
  //   i00: "1069",
  //   lj: "765",
  //   sp: "1247",
  //   hj: "202",
  //   A00: "4782",
  //   ii0h: "1412",
  //   dt: "3558",
  //   pv: "490",
  //   jt: "5418",
  //   i500: "41630",
  // },
  // {
  //   name: "右代啓祐",
  //   i00: "1124",
  //   lj: "715",
  //   sp: "1519",
  //   hj: "203",
  //   A00: "4966",
  //   ii0h: "1490",
  //   dt: "5017",
  //   pv: "480",
  //   jt: "6911",
  //   i500: "43262",
  // },
  // {
  //   name: "奥田",
  //   i00: "1070",
  //   lj: "735",
  //   sp: "1267",
  //   hj: "194",
  //   A00: "4759",
  //   ii0h: "1454",
  //   dt: "3813",
  //   pv: "440",
  //   jt: "5499",
  //   i500: "43870",
  // },
  // {
  //   name: "片山",
  //   i00: "1093",
  //   lj: "710",
  //   sp: "1354",
  //   hj: "185",
  //   A00: "5094",
  //   ii0h: "1512",
  //   dt: "4267",
  //   pv: "460",
  //   jt: "6123",
  //   i500: "44789",
  // },
  // {
  //   name: "田上",
  //   i00: "1098",
  //   lj: "728",
  //   sp: "1254",
  //   hj: "186",
  //   A00: "4942",
  //   ii0h: "1400",
  //   dt: "3831",
  //   pv: "450",
  //   jt: "5907",
  //   i500: "42628",
  // },
];
export const currentHeptathlonDataInitial: heptathlonSingleRecord[] = [
  {
    name: 'ロード中--',
    i00h: '',
    hj: '',
    sp: '',
    z00: '',
    lj: '',
    jt: '',
    B00: '',
  },
];

export const sbHeptathlonDataInitial: heptathlonSingleRecord[] = [
  {
    name: 'ロード中--',
    i00h: '',
    hj: '',
    sp: '',
    z00: '',
    lj: '',
    jt: '',
    B00: '',
  },
];

export const pbHeptathlonDataInitial: heptathlonSingleRecord[] = [
  {
    name: 'ロード中--',
    i00h: '',
    hj: '',
    sp: '',
    z00: '',
    lj: '',
    jt: '',
    B00: '',
  },
];
