import { eventNameType, singleRecord } from '../../common/types';
import { calcPoint } from './calcPointForResult';

const eventKeysWithoutName = [
  'i00',
  'lj',
  'sp',
  'hj',
  'A00',
  'ii0h',
  'dt',
  'pv',
  'jt',
  'i500',
];

const calcTotalPoint = (IniData: any) => {
  let totalpoint = 0;
  for (const EventName of eventKeysWithoutName) {
    const score = calcPoint(EventName, IniData[EventName]);
    totalpoint += score;
  }
  return totalpoint;
};

const calcTotalPointByEvent = (title: eventNameType, IniData: any) => {
  let totalpoint = 0;
  for (const EventName of eventKeysWithoutName) {
    const score = calcPoint(EventName, IniData[EventName]);
    totalpoint += score;

    if (EventName === title) {
      break;
    }
  }
  return totalpoint;
};

const calcDay1Point = (IniData: any) => {
  let day1point = 0;
  for (const EventName of eventKeysWithoutName) {
    const score = calcPoint(EventName, IniData[EventName]);
    day1point += score;
    if (EventName === 'A00') {
      break;
    }
  }
  return day1point;
};

export const createShownData = (data: singleRecord) => {
  const totalPoint = calcTotalPoint(data);
  const day1Point = calcDay1Point(data);
  const day2Point = calcTotalPoint(data) - calcDay1Point(data);
  return {
    name: data.name,
    total: String(totalPoint),
    i00: data.i00,
    lj: data.lj,
    sp: data.sp,
    hj: data.hj,
    A00: data.A00,
    ii0h: data.ii0h,
    dt: data.dt,
    pv: data.pv,
    jt: data.jt,
    i500: data.i500,
    firstDay: String(day1Point),
    secondDay: String(day2Point),
  };
};
export const modalCreateShownData = (data: singleRecord) => {
  const totalPoint = calcTotalPoint(data);
  const day1Point = calcDay1Point(data);
  const day2Point = calcTotalPoint(data) - calcDay1Point(data);
  return {
    name: data.name,
    i00: data.i00,
    lj: data.lj,
    sp: data.sp,
    hj: data.hj,
    A00: data.A00,
    ii0h: data.ii0h,
    dt: data.dt,
    pv: data.pv,
    jt: data.jt,
    i500: data.i500,
    firstDay: String(day1Point),
    secondDay: String(day2Point),
    total: String(totalPoint),
  };
};

export const createForecastData = (
  currentData: singleRecord,
  bestData: singleRecord
) => {
  return {
    name: currentData.name,
    i00: !currentData.i00 ? bestData.i00 : currentData.i00,
    lj: !currentData.lj ? bestData.lj : currentData.lj,
    sp: !currentData.sp ? bestData.sp : currentData.sp,
    hj: !currentData.hj ? bestData.hj : currentData.hj,
    A00: !currentData.A00 ? bestData.A00 : currentData.A00,
    ii0h: !currentData.ii0h ? bestData.ii0h : currentData.ii0h,
    dt: !currentData.dt ? bestData.dt : currentData.dt,
    pv: !currentData.pv ? bestData.pv : currentData.pv,
    jt: !currentData.jt ? bestData.jt : currentData.jt,
    i500: !currentData.i500 ? bestData.i500 : currentData.i500,
  };
};

export const createEventForecastData = (
  title: eventNameType,
  currentData: singleRecord,
  bestData: singleRecord
) => {
  return {
    name: currentData.name,
    i00:
      currentData &&
      currentData.i00 &&
      [
        'i00',
        'lj',
        'sp',
        'hj',
        'A00',
        'ii0h',
        'dt',
        'pv',
        'jt',
        'i500',
      ].includes(title)
        ? currentData.i00
        : bestData.i00,
    lj:
      currentData &&
      currentData.lj &&
      ['lj', 'sp', 'hj', 'A00', 'ii0h', 'dt', 'pv', 'jt', 'i500'].includes(
        title
      )
        ? currentData.lj
        : bestData.lj,
    sp:
      currentData &&
      currentData.sp &&
      ['sp', 'hj', 'A00', 'ii0h', 'dt', 'pv', 'jt', 'i500'].includes(title)
        ? currentData.sp
        : bestData.sp,
    hj:
      currentData &&
      currentData.hj &&
      ['hj', 'A00', 'ii0h', 'dt', 'pv', 'jt', 'i500'].includes(title)
        ? currentData.hj
        : bestData.hj,
    A00:
      currentData &&
      currentData.A00 &&
      ['A00', 'ii0h', 'dt', 'pv', 'jt', 'i500'].includes(title)
        ? currentData.A00
        : bestData.A00,
    ii0h:
      currentData &&
      currentData.ii0h &&
      ['ii0h', 'dt', 'pv', 'jt', 'i500'].includes(title)
        ? currentData.ii0h
        : bestData.ii0h,
    dt:
      currentData &&
      currentData.dt &&
      ['dt', 'pv', 'jt', 'i500'].includes(title)
        ? currentData.dt
        : bestData.dt,
    pv:
      currentData && currentData.pv && ['pv', 'jt', 'i500'].includes(title)
        ? currentData.pv
        : bestData.pv,
    jt:
      currentData && currentData.jt && ['jt', 'i500'].includes(title)
        ? currentData.jt
        : bestData.jt,
    i500:
      currentData && currentData.i500 && ['i500'].includes(title)
        ? currentData.i500
        : bestData.i500,
  };
};

export const createEventShownData = (
  title: eventNameType,
  resultdata: singleRecord,
  sbDataInitial: singleRecord,
  pbDataInitial: singleRecord,
  cSB: singleRecord,
  cPB: singleRecord
) => {
  const sbForecastData = createEventForecastData(title, resultdata, cSB);

  const pbForecastData = createEventForecastData(title, resultdata, cPB);

  return {
    name: resultdata.name,
    result: resultdata[title],
    sb: sbDataInitial[title],
    pb: pbDataInitial[title],
    total: String(calcTotalPointByEvent(title, resultdata)),
    sbTotal: String(calcTotalPoint(sbForecastData)),
    pbTotal: String(calcTotalPoint(pbForecastData)),
  };
};
