import { Text } from '@chakra-ui/react';

const calc100hscore = (result: number) => {
  if (!result || result > 2670) {
    return 0;
  }
  return Math.trunc(9.23076 * (26.7 - Number(result) / 100) ** 1.835);
};
const calcHJscore = (result: number) => {
  if (!result || result < 75) {
    return 0;
  }
  return Math.trunc(1.84523 * (Number(result) - 75) ** 1.348);
};
const calcSPscore = (result: number) => {
  if (!result || result < 150) {
    return 0;
  }
  return Math.trunc(56.0211 * (Number(result) / 100 - 1.5) ** 1.05);
};

const calc200score = (result: number) => {
  if (!result || result > 4250) {
    return 0;
  }
  return Math.trunc(4.99087 * (42.5 - Number(result) / 100) ** 1.81);
};
const calcLJscore = (result: number) => {
  if (!result || result < 210) {
    return 0;
  }
  return Math.trunc(0.188807 * (Number(result) - 210) ** 1.41);
};

const calcJTscore = (result: number) => {
  if (!result || result < 380) {
    return 0;
  }
  return Math.trunc(15.9803 * (Number(result) / 100 - 3.8) ** 1.04);
};
const calc800score = (result: number) => {
  if (!result || result > 41400) {
    return 0;
  }
  return Math.trunc(
    0.11193 *
      (254 -
        (Math.trunc(Number(result) / 10000) * 60 +
          (Number(result) % 10000) / 100)) **
        1.88
  );
};
export const calcHeptathlonPointToJSX = (EventName: string, result: string) => {
  const kiroku = Number(result);
  if (['name'].includes(EventName)) {
    return (
      <Text fontSize={'7pt'} color={'GrayText'}>
        {String(result).split('-')[1] || ''}
      </Text>
    );
  }
  if (['i00h'].includes(EventName)) {
    return <>({calc100hscore(kiroku)})</>;
  }
  if (['lj'].includes(EventName)) {
    return <>({calcLJscore(kiroku)})</>;
  }
  if (['sp'].includes(EventName)) {
    return <>({calcSPscore(kiroku)})</>;
  }
  if (['hj'].includes(EventName)) {
    return <>({calcHJscore(kiroku)})</>;
  }
  if (['z00'].includes(EventName)) {
    return <>({calc200score(kiroku)})</>;
  }

  if (['jt'].includes(EventName)) {
    return <>({calcJTscore(kiroku)})</>;
  }
  if (['B00'].includes(EventName)) {
    return <>({calc800score(kiroku)})</>;
  }
};

export const calcHeptathlonPointToJSXForSort = (
  title: string,
  EventName: string,
  result: string
) => {
  const kiroku = Number(result);
  if (['name'].includes(EventName)) {
    return (
      <Text fontSize={'8pt'} color={'GrayText'}>
        {String(result).split('-')[1] || ''}
      </Text>
    );
  }
  if (['total', 'sbTotal', 'pbTotal'].includes(title)) {
    return;
  }
  if (['i00h'].includes(EventName)) {
    return <>({calc100hscore(kiroku)})</>;
  }
  if (['lj'].includes(EventName)) {
    return <>({calcLJscore(kiroku)})</>;
  }
  if (['sp'].includes(EventName)) {
    return <>({calcSPscore(kiroku)})</>;
  }
  if (['hj'].includes(EventName)) {
    return <>({calcHJscore(kiroku)})</>;
  }
  if (['z00'].includes(EventName)) {
    return <>({calc200score(kiroku)})</>;
  }

  if (['jt'].includes(EventName)) {
    return <>({calcJTscore(kiroku)})</>;
  }
  if (['B00'].includes(EventName)) {
    return <>({calc800score(kiroku)})</>;
  }
};

export const calcHeptathlonPoint = (EventName: string, result: string) => {
  const kiroku = Number(result);

  if (['total', 'firstDay', 'secondDay'].includes(EventName)) {
    return kiroku;
  }
  if (['i00h'].includes(EventName)) {
    return calc100hscore(kiroku);
  }
  if (['lj'].includes(EventName)) {
    return calcLJscore(kiroku);
  }
  if (['sp'].includes(EventName)) {
    return calcSPscore(kiroku);
  }
  if (['hj'].includes(EventName)) {
    return calcHJscore(kiroku);
  }
  if (['z00'].includes(EventName)) {
    return calc200score(kiroku);
  }

  if (['jt'].includes(EventName)) {
    return calcJTscore(kiroku);
  }
  if (['B00'].includes(EventName)) {
    return calc800score(kiroku);
  }

  return 0;
};

//
export const calcHeptathlonPointForSort = (
  title: string,
  EventName: string,
  result: string
) => {
  const kiroku = Number(result);

  if (['name', 'total', 'sbTotal', 'pbTotal'].includes(title)) {
    return kiroku;
  }
  if (['i00h'].includes(EventName)) {
    return calc100hscore(kiroku);
  }
  if (['lj'].includes(EventName)) {
    return calcLJscore(kiroku);
  }
  if (['sp'].includes(EventName)) {
    return calcSPscore(kiroku);
  }
  if (['hj'].includes(EventName)) {
    return calcHJscore(kiroku);
  }
  if (['z00'].includes(EventName)) {
    return calc200score(kiroku);
  }

  if (['jt'].includes(EventName)) {
    return calcJTscore(kiroku);
  }
  if (['B00'].includes(EventName)) {
    return calc800score(kiroku);
  }

  return 0;
};
