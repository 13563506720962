import { Text } from '@chakra-ui/react';

export const shownResult = (point: string, EventName: string, profile = []) => {
  if ([''].includes(point)) {
    return '-';
  }
  if (['NM', 'DNS', 'DNF', 'DQ'].includes(point)) {
    return point;
  }
  if (['name'].includes(EventName)) {
    return (
      <Text fontSize={'9.5pt'} fontWeight={'bold'}>
        {point.split('-')[0] || ''}
      </Text>
    );
  }
  if (
    ['total', 'firstDay', 'secondDay', 'sbTotal', 'pbTotal'].includes(EventName)
  ) {
    return <>{point}点</>;
  }
  if (['i500', 'B00'].includes(EventName)) {
    return (
      point.slice(0, -4) + '.' + point.slice(1, -2) + '.' + point.slice(-2)
    );
  }
  if (point.length === 5) {
    return (
      point.slice(0, -4) + '.' + point.slice(1, -2) + '.' + point.slice(-2)
    );
  }
  return point.slice(0, -2) + '.' + point.slice(-2);
};
