import React, { FC } from "react";
import { Input, InputGroup, InputRightAddon } from "@chakra-ui/input";

export const ScoreInput: FC<{
  record: string;
  point: string;
  title: string;
  handleScoreChange: (score: React.ChangeEvent<HTMLInputElement>) => void;
  handlePointChange: (score: React.ChangeEvent<HTMLInputElement>) => void;
  totalScore: number;
}> = ({
  record,
  point,
  title,
  handleScoreChange,
  handlePointChange,
  totalScore,
}) => {
  let boxLength = "4";
  if (["走高跳", "走幅跳", "棒高跳"].includes(title)) {
    boxLength = "3";
  } else if (title === "1500m" || title === "800m" || title === "1000m") {
    boxLength = "5";
  }

  return (
    <div style={{ marginLeft: "16px" }}>
      <div style={{ marginLeft: "20px" }}>{title}</div>
      <div style={{ display: "flex", width: "360px" }}>
        <div style={{ width: "30%" }}>
          <Input
            style={{ marginLeft: "18px" }}
            type="text"
            inputMode="numeric"
            size="xs"
            htmlSize={5}
            width="auto"
            maxLength={Number(boxLength)}
            value={record}
            onChange={handleScoreChange}
            backgroundColor="white"
            fontSize="16px"
          ></Input>
        </div>
        <div style={{ width: "30%" }}>
          <InputGroup size="xs">
            <Input
              style={{ textAlign: "right" }}
              type="text"
              inputMode="numeric"
              htmlSize={4}
              width="auto"
              maxLength={4}
              value={point}
              onChange={handlePointChange}
              backgroundColor="white"
              fontSize="16px"
            ></Input>
            <InputRightAddon
              backgroundColor="white"
              children="点"
            ></InputRightAddon>
          </InputGroup>
        </div>
        <div style={{ width: "20%", textAlign: "right" }}>合計：</div>
        <div style={{ width: "20%", textAlign: "right", marginRight: "30px" }}>
          {totalScore}点
        </div>
      </div>
    </div>
  );
};
