import twitter from '../../static/twitter.png';
import instagram from '../../static/instagram.png';
import tiktok from '../../static/tiktok.png';
import hagiwara from '../../static/ProfileWoman/hagiwara.png';
import ritou from '../../static/ProfileWoman/ritou.png';
import yamasaki from '../../static/ProfileWoman/yamasaki.png';
import oodama from '../../static/ProfileWoman/oodama.png';
import atsuta from '../../static/ProfileWoman/atsuta.png';
import mizutani from '../../static/ProfileWoman/mizutani.png';
import oosuga from '../../static/ProfileWoman/oosuga.png';

// プロフィールデータの型定義
type Profile = {
  name: string;
  imageSrc: string;
  description: string;
  socialMedia: Array<{
    platform: string;
    icon: string;
    url: string;
    handle: string;
  }>;
};

// プロフィールリストの定義
export const profiles: Profile[] = [
  {
    name: '山﨑 有紀-スズキ',
    imageSrc: yamasaki,
    description:
      '愛車はスズキのハスラーです🚙\n黄色い服で競技場を駆け巡ります💨\nスズキポーズにご注目ください🫶',
    socialMedia: [
      {
        platform: 'Twitter',
        icon: twitter,
        url: 'https://twitter.com/yk_rkuma_66',
        handle: '@yk_rkuma_66',
      },
      {
        platform: 'Instagram',
        icon: instagram,
        url: 'https://www.instagram.com/yuki_yamasaki66/',
        handle: '@yuki_yamasaki66',
      },
    ],
  },

  {
    name: '萩原 このか-デカキッズAC',
    imageSrc: hagiwara,
    description: 'でっかい犬に包まれてます🐕\n応援よろしくお願いします！',
    socialMedia: [
      {
        platform: 'Instagram',
        icon: instagram,
        url: 'https://www.instagram.com/_k08t/',
        handle: '@_k08t',
      },
    ],
  },

  {
    name: '利藤 野乃花-わらべや日洋',
    imageSrc: ritou,
    description:
      'セブンイレブンのおにぎり🍙\n美味しいですよね？\n観戦のお供に是非どうぞ！',
    socialMedia: [
      {
        platform: 'Twitter',
        icon: twitter,
        url: 'https://twitter.com/noooka0708',
        handle: '@noooka0708',
      },
      {
        platform: 'Instagram',
        icon: instagram,
        url: 'https://www.instagram.com/ritonono_78/',
        handle: '@ritonono_78',
      },
    ],
  },
  {
    name: '大玉 華鈴-日体大SMG',
    imageSrc: oodama,
    description:
      '口を開けて笑い、よく喋ります🤭\n西の京から来ました🏃🏻‍♀️\nどうぞよろしくお願いします！',
    socialMedia: [
      {
        platform: 'Twitter',
        icon: twitter,
        url: 'https://twitter.com/ka10ri21n',
        handle: '@ka10ri21n',
      },
      {
        platform: 'Instagram',
        icon: instagram,
        url: 'https://www.instagram.com/ka_rin.1021/',
        handle: '@ka_rin.1021',
      },
    ],
  },

  {
    name: '熱田 心-岡山陸協',
    imageSrc: atsuta,
    description:
      'この頃から腹筋やってました！？\nきび団子もらえたら仲間になります🍡',
    socialMedia: [
      {
        platform: 'Twitter',
        icon: twitter,
        url: 'https://twitter.com/cocoro_a0718',
        handle: '@cocoro_a0718',
      },
      {
        platform: 'Instagram',
        icon: instagram,
        url: 'https://www.instagram.com/cocoro.atsuta/',
        handle: '@cocoro.atsuta',
      },
    ],
  },
  {
    name: '水谷 佳歩-中京大',
    imageSrc: mizutani,
    description: '大阪生まれ🐙の秘密兵器です！？！？\n根性で頑張ります！',
    socialMedia: [],
  },
  {
    name: '大菅 紗矢香-中京大',
    imageSrc: oosuga,
    description: '京都🍵出身の大菅です！\nぎょうさんきばります！',
    socialMedia: [],
  },
  // 他のプロフィールを追加可能
];
