import { useEffect, useState } from 'react';
import Papa from 'papaparse';
import { heptathlonSingleRecord, singleRecord } from '../../common/types';
import {
  currentDataInitial,
  currentHeptathlonDataInitial,
  pbDataInitial,
  pbHeptathlonDataInitial,
  sbDataInitial,
  sbHeptathlonDataInitial,
} from '../components/constants/sbdatainitial';

export const useResultData = () => {
  const [data, setData] = useState<singleRecord[]>(currentDataInitial);
  useEffect(() => {
    Papa.parse(
      'https://docs.google.com/spreadsheets/d/e/2PACX-1vTshLj3hhvRDAgToETLwEPx2Ylop6PDdCzMuiYFAXmPIdyFPwRbG_BdFW9NqvEQ_FNUFqGcx3kq4GFx/pub?gid=0&single=true&output=csv',
      {
        download: true,
        header: true,
        complete: (results: any) => {
          setData(results.data);
        },
      }
    );
  }, []);
  return data;
};

export const useHeptathlonResultData = () => {
  const [dataHeptathlon, setDataHeptathlon] = useState<
    heptathlonSingleRecord[]
  >(currentHeptathlonDataInitial);
  useEffect(() => {
    Papa.parse(
      'https://docs.google.com/spreadsheets/d/e/2PACX-1vTshLj3hhvRDAgToETLwEPx2Ylop6PDdCzMuiYFAXmPIdyFPwRbG_BdFW9NqvEQ_FNUFqGcx3kq4GFx/pub?gid=1645265925&single=true&output=csv',
      {
        download: true,
        header: true,
        complete: (results: any) => {
          setDataHeptathlon(results.data);
        },
      }
    );
  }, []);
  return dataHeptathlon;
};

export const useSbData = () => {
  const [sbdata, setSbData] = useState<singleRecord[]>(sbDataInitial);
  useEffect(() => {
    Papa.parse(
      'https://docs.google.com/spreadsheets/d/e/2PACX-1vTshLj3hhvRDAgToETLwEPx2Ylop6PDdCzMuiYFAXmPIdyFPwRbG_BdFW9NqvEQ_FNUFqGcx3kq4GFx/pub?gid=1399017830&single=true&output=csv',
      {
        download: true,
        header: true,
        complete: (results: any) => {
          setSbData(results.data);
        },
      }
    );
  }, []);

  return sbdata;
};

export const useHeptathlonSbData = () => {
  const [sbdataHeptathlon, setSbDataHeptathlon] = useState<
    heptathlonSingleRecord[]
  >(sbHeptathlonDataInitial);
  useEffect(() => {
    Papa.parse(
      'https://docs.google.com/spreadsheets/d/e/2PACX-1vTshLj3hhvRDAgToETLwEPx2Ylop6PDdCzMuiYFAXmPIdyFPwRbG_BdFW9NqvEQ_FNUFqGcx3kq4GFx/pub?gid=308428280&single=true&output=csv',
      {
        download: true,
        header: true,
        complete: (results: any) => {
          setSbDataHeptathlon(results.data);
        },
      }
    );
  }, []);
  return sbdataHeptathlon;
};

export const usePbData = () => {
  const [pbdata, setPbData] = useState<singleRecord[]>(pbDataInitial);
  useEffect(() => {
    Papa.parse(
      'https://docs.google.com/spreadsheets/d/e/2PACX-1vTshLj3hhvRDAgToETLwEPx2Ylop6PDdCzMuiYFAXmPIdyFPwRbG_BdFW9NqvEQ_FNUFqGcx3kq4GFx/pub?gid=771134663&single=true&output=csv',
      {
        download: true,
        header: true,
        complete: (results: any) => {
          setPbData(results.data);
        },
      }
    );
  }, []);
  return pbdata;
};

export const useHeptathlonPbData = () => {
  const [pbdataHeptathlon, setPbDataHeptathlon] = useState<
    heptathlonSingleRecord[]
  >(pbHeptathlonDataInitial);
  useEffect(() => {
    Papa.parse(
      'https://docs.google.com/spreadsheets/d/e/2PACX-1vTshLj3hhvRDAgToETLwEPx2Ylop6PDdCzMuiYFAXmPIdyFPwRbG_BdFW9NqvEQ_FNUFqGcx3kq4GFx/pub?gid=1792059955&single=true&output=csv',
      {
        download: true,
        header: true,
        complete: (results: any) => {
          setPbDataHeptathlon(results.data);
        },
      }
    );
  }, []);
  return pbdataHeptathlon;
};
