import { ExternalLinkIcon } from "@chakra-ui/icons";
import { Button } from "@chakra-ui/react";
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverArrow,
  PopoverCloseButton,
} from "@chakra-ui/react";
import { HepPointgaps } from "../../common/pointGaps";
import { heptathlonEventType } from "../../common/types";

export const approximateHeptathlonPointButton = (
  event: heptathlonEventType
) => {
  return (
    <Popover>
      <PopoverTrigger>
        <Button size="sm" fontWeight="light" variant="link">
          得点差の目安
          <ExternalLinkIcon />
        </Button>
      </PopoverTrigger>
      <PopoverContent maxWidth="180px">
        <PopoverArrow />
        <PopoverCloseButton />
        <PopoverHeader>おおよその得点差</PopoverHeader>
        <PopoverBody>{HepPointgaps[event]}</PopoverBody>
      </PopoverContent>
    </Popover>
  );
};
