import React from 'react';
import { Button, useDisclosure } from '@chakra-ui/react';
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
} from '@chakra-ui/react';
import { DeleteIcon } from '@chakra-ui/icons';

export const HowToName = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const cancelRef = React.useRef(null);

  const LoadButton = () => {
    return (
      <>
        <Button style={{ marginRight: '5px', marginLeft: '5px' }} size={'xs'}>
          Load
        </Button>
        <Button size={'xs'}>
          <DeleteIcon />
        </Button>
      </>
    );
  };

  return (
    <>
      <Button variant="link" size="xs" onClick={onOpen}>
        <u>名前の付け方</u>
      </Button>

      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              おすすめの名前の付け方{' '}
            </AlertDialogHeader>

            <AlertDialogBody>
              <>
                年度やタイトルを名前に入れると分かりやすいです
                <br />
                <br />
                例)
                <br />
                ・2024年　各種目の自己ベスト{LoadButton()}
                <br />
                ・2024年　日本選手権　結果 {LoadButton()}
                <br />
                ・2024年　日本選手権　目標 {LoadButton()}
                <br />
                ・日本選手権　途中経過 {LoadButton()}
                <br /> <br />
                <Button size={'xs'}>Load</Button>
                を押すと保存された記録が表示されます
                <br />
                <Button size={'xs'}>
                  <DeleteIcon />{' '}
                </Button>
                を押すとリストから削除されます
              </>
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose} colorScheme="blue">
                戻る
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};
