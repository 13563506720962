import React from 'react';
import { ChakraProvider } from '@chakra-ui/react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { DecathlonResultTable } from './page_ResultTable/DecathlonResultTable';
import { DecathlonInputTable } from './page_ResultTable/components/DecathlonInputTable';
import { DemoDecathlonRankingTable } from './page_ResultTable/components/DemoDecathlonRankingTable';
import { CalculatorDecathlon } from './page_calculator/CalculatorDecathlon';
import { CalculatorHeptathlon } from './page_calculator/CalculatorHeptathlon';
import { CalculatorOctathlon } from './page_calculator/CalculatorOctathlon';
import { CalculatorIndoorHeptathlon } from './page_calculator/CalculatorIndoorHeptathlon';
import { CalculatorIndoorPentathlon } from './page_calculator/CalculatorIndoorPentathlon';
import { CalculatorTetrathlonMan } from './page_calculator/CalculatorTetrathlonMan';
import { CalculatorTetratathlonWoman } from './page_calculator/CalculatorTetrathlonWoman';
import { PointGapsList } from './page_pointGapList/PointGapList';
import { VariousCombinedEventsRecord } from './page_VariousCombinedEventsRecord/VariousCombinedEventsRecord';
import { HeptathlonResultTable } from './page_ResultTable/HeptathlonResultTable';
import { U20DecathlonResultTable } from './page_ResultTable/U20DecathlonResultTable';
import { U20HeptathlonResultTable } from './page_ResultTable/U20HeptathlonResultTable';
import { CalculatorDecathlonSave } from './page_calculator/CalculatorDecathlonSave';
import { CalculatorHeptathlonSave } from './page_calculator/CalculatorHeptathlonSave';
import { CalculatorOctathlonSave } from './page_calculator/CalculatorOctathlonSave';
import { CalculatorTetrathlonManSave } from './page_calculator/CalculatorTetrathlonManSave';
import { CalculatorTetratathlonWomanSave } from './page_calculator/CalculatorTetrathlonWomanSave';
import { TopPage } from './page_top/topPage';
import ResettableModal from './test';

function App() {
  return (
    <>
      <ChakraProvider>
        <BrowserRouter>
          <Routes>
            <Route path={`/`} element={<TopPage />} />
            <Route path={`/test`} element={<ResettableModal />} />

            <Route path={`/dec`} element={<DecathlonResultTable />} />
            <Route path={`/hep`} element={<HeptathlonResultTable />} />
            <Route path={`/U20dec`} element={<U20DecathlonResultTable />} />
            <Route path={`/U20hep`} element={<U20HeptathlonResultTable />} />

            <Route path={`/decathloninput`} element={<DecathlonInputTable />} />
            <Route path={`/demo`} element={<DemoDecathlonRankingTable />} />
            {/* <Route path={`/`} element={<CalculatorDecathlon />} /> */}
            <Route
              path={`/decathloncalculator`}
              element={<CalculatorDecathlonSave />}
            />

            <Route
              path={`/heptathloncalculator`}
              // element={<CalculatorHeptathlon />}
              element={<CalculatorHeptathlonSave />}
            />
            <Route
              path={`/octathloncalculator`}
              // element={<CalculatorOctathlon />}
              element={<CalculatorOctathlonSave />}
            />
            <Route
              path={`/indoorheptathloncalculator`}
              element={<CalculatorIndoorHeptathlon />}
            />
            <Route
              path={`/indoorpentathloncalculator`}
              element={<CalculatorIndoorPentathlon />}
            />
            <Route
              path={`/tetrathlonmancalculator`}
              // element={<CalculatorTetrathlonMan />}
              element={<CalculatorTetrathlonManSave />}
            />
            <Route
              path={`/tetrathlonwomancalculator`}
              // element={<CalculatorTetratathlonWoman />}
              element={<CalculatorTetratathlonWomanSave />}
            />
            <Route path={`/decathlonlist`} element={<PointGapsList />} />
            <Route
              path={`/variousrecord`}
              element={<VariousCombinedEventsRecord />}
            />
          </Routes>
        </BrowserRouter>
      </ChakraProvider>
    </>
  );
}

export default App;
